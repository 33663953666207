import { Box, Button } from "@mui/material";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import Title from "src/Shared/Components/Title/Title";
import { useStyles } from "./CompanyContactGrid.styles";
import { CustomDataGrid } from "src/Shared/Components";
import { contactColumns } from "./CompanyContactGrid.column";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { useEffect, useState } from "react";
import { IPageRequest } from "src/Shared/Interfaces";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IContactFilter } from "../../Interfaces/IContactFilter";
import { ICompanyContact } from "../../Interfaces/ICompanyContact";
import { useParams } from "react-router";
import { IRouteParams } from "../../Interfaces";
import AddOrEditContact from "../AddOrEditContact/AddOrEditContact";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { useModal } from "src/Shared/Hooks";
import { observer } from "mobx-react";

const CompanyContactGrid: React.FC = () => {
  const {
    rootStore: {
      companyContactStore: {
        gridLoading,
        companyContactList,
        getCompanyContact,
        refreshCompanyContactList,
        primaryContactID,
      },
    },
  } = useStore();
  const classes = useStyles();
  const { companyRowID } = useParams<Partial<IRouteParams>>();

  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  const modal = useModal();

  const updateContact = (companyContact: ICompanyContact) => {
    modal.open(
      <AddOrEditContact
        mode={VIEW_MODE.EDIT}
        companyContact={companyContact}
        companyRowID={companyRowID}
        primayContactId={primaryContactID}
      />
    );
  };

  const addNewContact = () => {
    modal.open(
      <AddOrEditContact
        mode={VIEW_MODE.ADD}
        companyRowID={companyRowID}
        primayContactId={primaryContactID}
      />
    );
  };

  const [request, setRequest] = useState<IPageRequest<IContactFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      companyRowID: companyRowID,
    },
    sortCollection: [],
  });

  useEffect(() => {
    getCompanyContact(request);
  }, [request]);

  return (
    <>
      <MainContentBox>
        <Title title="Client Contacts"></Title>

        <Box className={classes.root}>
          <Button variant="contained" onClick={addNewContact}>
            Add Contacts
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          <CustomDataGrid
            loading={gridLoading}
            rows={companyContactList.results || []}
            columns={contactColumns(updateContact)}
            rowCount={companyContactList.totalNumberOfRecords || 0}
            getRowId={(row: ICompanyContact) => row.companyContactID}
            style={{
              height: "calc(100vh - 245px)",
            }}
            paginationModel={gridPagination}
            onPaginationModelChange={(model, _) =>
              onGridPaginationChange(model)
            }
            onRowDoubleClick={(params) => updateContact(params.row)}
          />
        </Box>
      </MainContentBox>
    </>
  );
};

export default observer(CompanyContactGrid);
