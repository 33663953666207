import { IPageRequest, IPagedResult, IResult } from "src/Shared/Interfaces";
import HttpClient from "src/Shared/Tools/HttpClient";
import { Observable } from "rxjs";
import { ISalesDealFilter } from "../Interfaces/ISalesDealFilter";
import { ISalesDeal, ISalesDealHistory } from "../Interfaces";
import { BaseService } from "src/Shared/Services/Base.service";
import { SaleDealApiUrls } from "../Tools/SaleDealApiUrls";
import { ISalesDealHistoryFilter } from "../Interfaces/ISalesDealHistoryFilter";

class SalesDealService extends BaseService {
  public getSalesDealList(
    request: IPageRequest<ISalesDealFilter>
  ): Observable<IPagedResult<ISalesDeal>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ISalesDeal>>(
      SaleDealApiUrls.GetSalesDeals,
      request
    );
  }
  public addSaleDeal(request: ISalesDeal) {
    return this.http.post<IResult<string>>(
      SaleDealApiUrls.AddSalesDeal,
      request
    );
  }

  public updateSaleDeal(request: ISalesDeal) {
    return this.http.post<IResult<string>>(
      SaleDealApiUrls.UpdateSalesDeal,
      request
    );
  }

  public getSalesDealHistory(
    request: IPageRequest<ISalesDealHistoryFilter>
  ): Observable<IPagedResult<ISalesDealHistory>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ISalesDealHistory>>(
      SaleDealApiUrls.GetSalesDealHistory,
      request
    );
  }
}

export default new SalesDealService();
