import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./Title.styles";
import { useModal } from "src/Shared/Hooks";
import WarningPopup from "../WarningPopup/WarningPopup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { alignProperty } from "@mui/material/styles/cssUtils";

type Props = {
  title: string;
  backButtonEnabled?: boolean;
  warningRequired?: boolean;
};
const Title: React.FC<Props> = ({
  title,
  warningRequired,
  backButtonEnabled,
}) => {
  const classes = useStyles();
  const modal = useModal();
  const navigate = useNavigate();

  const handleClick = () => {
    if (warningRequired) {
      modal.open(<WarningPopup navigate={navigate} />);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        {backButtonEnabled && (
          <IconButton color="secondary" onClick={handleClick}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography className={classes.title} variant="h4" gutterBottom>
          {title}
        </Typography>
      </Box>
    </>
  );
};

Title.defaultProps = {
  backButtonEnabled: true,
  warningRequired: false,
};
export default Title;
