import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const fullSize = {
  position: "absolute" as any,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};
export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    ...fullSize,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 400,
    backgroundColor: "white",
  },
}));
