import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BadgeIcon from "@mui/icons-material/Badge";
import LanguageIcon from "@mui/icons-material/Language";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AttributionIcon from "@mui/icons-material/Attribution";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ModeIcon from "@mui/icons-material/Mode";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import { useModal, useStore } from "src/Shared/Hooks";
import AddComments from "../AddComments/AddComments";
import { Link } from "react-router-dom";
import { numericFormatter } from "react-number-format";
import { ICompanyOverview } from "../../Interfaces/ICompanyOverview";
import { useStyles } from "./CompanyOverviewPanel.style";
import { panelList } from "./CompanyOverviewPanel.list";

type Props = {
  companyOverview?: ICompanyOverview;
};

const CompanyOverviewPanel: React.FC<Props> = ({ companyOverview }) => {
  const modal = useModal();

  const classess = useStyles();
  const listToString = (list: string[]) => {
    return list?.join(", ") || "";
  };

  const handleOpen = () => {
    modal.open(<AddComments companyRowID={companyOverview.companyRowID} />);
  };
  return (
    <>
      {companyOverview && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <GroupIcon
              fontSize="large"
              sx={{ verticalAlign: "middle", mr: 1 }}
            />
            <Typography variant="h5" sx={{}}>
              Company Information
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {panelList(companyOverview).map((item, index) => {
              return (
                <Grid container xs={5} key={index}>
                  <Grid item sx={{ p: 2 }} xs={12}>
                    <InputLabel>
                      <IconButton>{item.icon}</IconButton>
                      {item.label}
                    </InputLabel>
                    <Typography sx={{ textAlign: "center" }}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}

            {/* <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <MyLocationIcon />
                  </IconButton>
                  National/Regional:
                </InputLabel>
                <Typography>{companyOverview.companyType}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <AttachMoneyIcon />
                  </IconButton>
                  Sales Pipeline Value:
                </InputLabel>
                <Typography>
                  {numericFormatter(`${companyOverview.salesTotalValue}`, {
                    thousandSeparator: true,
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    prefix: "$",
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <MyLocationIcon />
                  </IconButton>
                  Supporting Region:
                </InputLabel>
                <Typography>{companyOverview.franchiseName}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <BadgeIcon />
                  </IconButton>
                  TDDA/Healthtick:
                </InputLabel>
                <Typography>
                  {listToString(companyOverview.vendorTypes)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <LanguageIcon />
                  </IconButton>
                  Services:
                </InputLabel>
                <Typography>
                  {listToString(companyOverview.services)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <SocialDistanceIcon />
                  </IconButton>
                  TDDA Relationship Manager:
                </InputLabel>
                <Typography>
                  {listToString(companyOverview.companyRelationshipManagers)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <SupervisorAccountIcon />
                  </IconButton>
                  CompanySize:
                </InputLabel>
                <Typography>{companyOverview.totalStaffNumber}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <AttributionIcon />
                  </IconButton>
                  Lead:
                </InputLabel>
                <Typography>{companyOverview.leadType}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <LocalOfferIcon />
                  </IconButton>
                  Deal Stage:
                </InputLabel>
                <Typography>{companyOverview.dealStage}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <ModeIcon />
                  </IconButton>
                  Signed SLA:
                </InputLabel>
                <Typography>
                  {companyOverview.isSLASigned ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <DateRangeIcon />
                  </IconButton>
                  SLA Expiry Date:
                </InputLabel>
                <Typography>{companyOverview.slaExpiryDate || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <AttachmentIcon />
                  </IconButton>
                  Attachments:
                </InputLabel>
                <Typography>---</Typography>
              </Grid>
            </Grid>
            <Grid container xs={5}>
              <Grid item sx={{ p: 2 }} xs={12}>
                <InputLabel htmlFor="standard-adornment-amount">
                  <IconButton>
                    <ContactsIcon />
                  </IconButton>
                  Client Primary Content:
                </InputLabel>
                <Typography>{companyOverview.primaryContactName}</Typography>
              </Grid>
            </Grid> */}
          </Grid>
          <Box className={classess.buttonFormating}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                component={Link}
                to={`/company/${companyOverview.companyRowID}/edit`}
                // startIcon={<EditTwoToneIcon fontSize="medium" />}
              >
                Edit Company
              </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                component={Link}
                to={`/company/${companyOverview.companyRowID}/contacts`}
                // startIcon={<EditTwoToneIcon fontSize="medium" />}
              >
                Edit Contacts
              </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                component={Link}
                to={`/company/${companyOverview.companyRowID}/offices`}
                // startIcon={<EditTwoToneIcon fontSize="medium" />}
              >
                Edit Offices
              </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                onClick={handleOpen}
                // startIcon={<CommentIcon fontSize="medium" />}
              >
                comments
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CompanyOverviewPanel;
