import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { ISalesAction } from "../Interfaces/ISalesAction";
import { action, makeObservable, observable } from "mobx";
import { ISalesDealFilter } from "../Interfaces/ISalesDealFilter";
import { Subscription, finalize } from "rxjs";
import SalesActionService from "../Services/SalesAction.service";
import { AxiosError } from "axios";
import DateTimeUtils from "../../../Shared/Tools/DateTimeUtils";
import { ToastService } from "src/Shared/Services";
import { IActionNote } from "../Interfaces/IActionNote";

export class SalesActionStore {
  rootStore: IRootStore;
  public isNoteBtnLoading: boolean = false;
  public gridLoading: boolean = false;
  public notesList: IActionNote[] = [];
  public salesActionList: IPagedResult<ISalesAction> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      setGridLoading: action,
      salesActionList: observable,
      notesList: observable,
      setSalesGridList: action,
      getActionNotesList: action.bound,
      getSalesActionGridList: action.bound,
      refreshSalesActionGridList: action,
      setNotesList: action,
      setNoteBtnLoading: action,
      isNoteBtnLoading: observable,
    });
    this.rootStore = rootStore;
  }

  // const [isNoteBtnLoading, setNoteBtnLoading]

  setNoteBtnLoading = (flag: boolean) => {
    this.isNoteBtnLoading = flag;
  };

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };
  getSalesActionGridList = (
    request: IPageRequest<ISalesDealFilter>
  ): Subscription => {
    this.setGridLoading(true);
    return SalesActionService.getSalesActionList(request)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setSalesGridList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  refreshSalesActionGridList = (companyRowID: string) => {
    this.getSalesActionGridList({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
    });
  };

  setSalesGridList(data: IPagedResult<ISalesAction>) {
    if (data) {
      data.results = data.results.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.updatedOn = DateTimeUtils.dateToLocalTimeZone(x.updatedOn);
        x.taskDueDateFormated = DateTimeUtils.dateToLocalDateZone(
          x.taskDueDate
        );
        x.taskDueDate = DateTimeUtils.dateToLocalTimeZone(x.taskDueDate);
        return x;
      });
      this.salesActionList = data;
    }
  }

  getActionNotesList(companyActionID: number): Subscription {
    this.setGridLoading(true);
    return SalesActionService.getActionNotes(companyActionID)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setNotesList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  }

  setNotesList(data: IActionNote[]) {
    if (data) {
      data = data.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.note = this.replaceDateTime(x.note);
        return x;
      });
      this.notesList = data;
    }
  }

  replaceDateTime(notes: string): string {
    const pattern: RegExp = /\b\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}\b/g;
    const matchPattern: RegExpMatchArray | null = notes.match(pattern);

    if (matchPattern) {
      for (const date of matchPattern) {
        const localDate: string = DateTimeUtils.dateToLocalDateZone(date);
        notes = notes.replace(date, localDate);
      }
    }
    return notes;
  }
}
