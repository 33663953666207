import { Observable } from "rxjs";
import { ISalesAction } from "src/Modules/Company/Interfaces";
import { SalesActionApiUrls } from "src/Modules/Company/Tools/SalesActionApiUrls";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { BaseService } from "src/Shared/Services/Base.service";
import HttpClient from "src/Shared/Tools/HttpClient";
import { IUserActionsFilter } from "../Interfaces/IUserActionsFilter";

class MyActionsService extends BaseService {

  public getMyTasksList(
    request: IPageRequest<IUserActionsFilter>
  ): Observable<IPagedResult<ISalesAction>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ISalesAction>>(
      SalesActionApiUrls.MyTaskViewList,
      request
    );
  }

  public getTasksListAssignedByUser(
    request: IPageRequest<IUserActionsFilter>
  ): Observable<IPagedResult<ISalesAction>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ISalesAction>>(
      SalesActionApiUrls.AssignedActionsByUser,
      request
    );
  }
}

export default new MyActionsService();
