import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFormValidation } from "src/Shared/Components";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import Title from "src/Shared/Components/Title/Title";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { IRouteParams } from "../../Interfaces/IRouteParams";
import { fields, hooks, labels, rules, types, values } from "./fields";
import { Box, Button } from "@mui/material";
import { EDITOR_TYPES } from "src/Shared/Enums";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import { useStyles } from "./CompanyOffices.styles";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { observer } from "mobx-react";
import { IAddCompanySites, ICompanyOffice } from "../../Interfaces";
import { finalize } from "rxjs";
import { IResult } from "src/Shared/Interfaces";
import { ToastService } from "src/Shared/Services";
import { AxiosError } from "axios";
import { useStore } from "src/Shared/Hooks";
import CompanyOfficeService from "../../Services/CompanyOffice.service";

const CompanyOffice: React.FC = () => {
  const sitesForm = useMemo(
    () =>
      getFormValidation(
        { fields, labels, values, rules, types, hooks },
        {
          isNested: true,
        }
      ),
    []
  );

  const {
    rootStore: {
      uiStore,
      companyOfficeStore: { resetCompanyList },
    },
  } = useStore();

  const { companyRowID } = useParams<Partial<IRouteParams>>();

  const navigate = useNavigate();
  const classes = useStyles();

  const gridProps: IGridFormControlProps = {
    row: 2,
    col: 5,
    spacing: 4,
    itemResponsive: {
      lg: 6,
      md: 6,
      sm: 12,
    },
    inputControls: [
      {
        fieldKey: "officeName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "division",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "streetAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "suburb",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "city",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "state",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "postCode",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "totalNumberOfEmployees",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "isPrimaryOffice",
        type: EDITOR_TYPES.SWITCH,
      },
    ],
  };

  const sites = sitesForm.$("sites");

  const handleAddInput = () => {
    sites.add({
      companyRowID: "",
      officeName: "",
      division: "",
      streetAddress: "",
      suburb: "",
      city: "",
      state: "",
      postCode: "",
      totalNumberOfEmployees: "",
      isPrimaryOffice: false,
    });
  };

  const handleDeleteInput = (key) => {
    sites.del(key);
  };

  const onSubmit = () => {
    if (!sitesForm.isValid) {
      sitesForm.showErrors(true);
      return;
    }
    const request: ICompanyOffice[] = [...sitesForm.values().sites];
    request.map((x) => {
      x.isPrimaryOffice =
        x.isPrimaryOffice == "" || x.isPrimaryOffice == null
          ? false
          : x.isPrimaryOffice;
      return x;
    });
    request.forEach((x) => (x.companyRowID = companyRowID));
    uiStore.setGlobalLoader(true);
    CompanyOfficeService.addCompanySite(request)
      .pipe(
        finalize(() => {
          uiStore.setGlobalLoader(false);
        })
      )
      .subscribe({
        next: (data: IResult<string>) => {
          resetCompanyList();
          ToastService.success(data.message);
          navigate(`/company`);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  return (
    <>
      <MainContentBox>
        <Title warningRequired title="Add New Client - Site"></Title>
        <Box className={classes.root}>
          {sites &&
            sites.map((field, index) => {
              return (
                <Box key={index} sx={{ mt: 4 }}>
                  <GridFormControl key={index} {...gridProps} form={field} />
                  {index > 0 && (
                    <Box className={classes.submitButtonBox}>
                      <Button
                        color="primary"
                        onClick={() => handleDeleteInput(field.key)}
                        startIcon={<DeleteTwoToneIcon />}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            })}
          <Button
            sx={{ height: "50px" }}
            color="primary"
            onClick={() => handleAddInput()}
            startIcon={<ApartmentIcon />}
          >
            Add New Site
          </Button>
        </Box>
        <Box className={classes.submitButtonBox}>
          <Button variant="contained" onClick={() => onSubmit()}>
            Add contacts
          </Button>
        </Box>
      </MainContentBox>
    </>
  );
};

export default observer(CompanyOffice);
