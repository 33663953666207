export enum DATE_FORMAT {
  UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss',
  API_TIME_FORMAT = 'HH:mm',
  DATE_FORMAT = 'DD/MM/YYYY',
  TIME_FORMAT = 'hh:mm A',
  NAME_FORMAT = 'DD MMM YYYY',
  MONTH_FORMAT = 'MM/DD/YYYY',
  COMMA_FORMAT = 'MMM DD, YYYY',
  DATE_TIME = 'MMM DD, YYYY hh:mm a',
  SLASH_DATE_TIME = 'MM/DD/YYYY hh:mm A'
}

// GRID_DISPLAY = 'DD/MM/YYYY, hh:mm A',
//   API_FORMAT = 'YYYY-MM-DDTHH:mm:ss',
//   MONTH_FIRST_DISPLAY = 'MM/DD/YYYY',
//   DATE_FIRST_DISPLAY = 'DD/MM/YYYY',
//   MONTH_TIME_DISPLAY = 'MM/DD/YYYY, h:mm A',
//   HYPHEN_FORMAT = 'MM-DD-YYYY',
//   GRID_FORMAT_DATE_TIME = 'DD-MM-YYYY HH:mm',
//   MONTH_FIRST_DATE_TIME = 'MM-DD-YYYY hh:mm a',
//   GRID_FORMAT = 'DD-MM-YYYY HH:mm a',
//   YEAR_MONTH_DATE = 'YYYY-MM-DD',
//   HOURS_MINUTES_ONLY = 'HH:mm',
//   SCHEDULER_DATE_HEADER = 'ddd, M/D',
