const baseCommonApiUrl = "/api/Common";

export const COMMON_API_URLS = {
  ActionStatusList: `${baseCommonApiUrl}/ActionStatusList`,
  ActionTaskTypeList: `${baseCommonApiUrl}/ActionTaskTypeList`,
  ActionTypeList: `${baseCommonApiUrl}/ActionTypeList`,
  DealStatusList: `${baseCommonApiUrl}/DealStatusList`,
  LeadTypeList: `${baseCommonApiUrl}/LeadTypeList`,
  ServiceTypeList: `${baseCommonApiUrl}/ServiceTypeList`,
  SupportiveRegions: `${baseCommonApiUrl}/SupportiveRegions`,
  VendorTypes: `${baseCommonApiUrl}/VendorTypes`,
  Industries: `${baseCommonApiUrl}/Industries`,
  Users: (userName?: string, usersCount?: number) =>
    `${baseCommonApiUrl}/users?userName=${userName}&usersCount=${usersCount}`,
};
