import moment from "moment";

class DateTimeUtils {
  dateToLocalTimeZone(date: any) {
    return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
  }
  dateToLocalTimeZoneDate(date: any) {
    return moment.utc(date).local();
  }
  dateToLocalDateZone(date: any) {
    return moment.utc(date).local().format("DD/MM/YYYY");
  }
  toApiDate(date: any) {
    return moment(date, "DD/MM/YYYY hh:mm A").utc().format();
  }
}

export default new DateTimeUtils();
