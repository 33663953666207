import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles((theme: Theme) => ({
  loginBox: {

  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "30px 15px",
  },
  inputField: {
    width: "100%",
    margin: theme.spacing(1),
  },
}));
