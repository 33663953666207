import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import MyActionsGrid from "../MyActionsGrid/MyActionsGrid";
import ActionAssignedByUserGrid from "../ActionAssignedByUserGrid/ActionAssignedByUserGrid";

const ActionTabs: React.FC = () => {
  const location = useLocation();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth" value={location.pathname.split("/")[2]}>
          <Tab
            value={"assigned-to-me"}
            sx={{ width: "100%" }}
            label="Assigned to Me"
            component={Link}
            to={`assigned-to-me`}
          />
          <Tab
            value={"assigned-by-me"}
            sx={{ width: "100%" }}
            label="Assigned by Me"
            component={Link}
            to={`assigned-by-me`}
            aria-selected={false}
          />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default ActionTabs;
