import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { IconButton } from "@mui/material";

export const contactColumns = (updateContact): GridColDef[] => {
  return [
    {
      field: "fullName",
      headerName: "Full Name",
      editable: false,
      width: 400,
      sortable: false,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "emailAddress",
      headerName: "Email",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "staffPrimaryOfficeAddress",
      headerName: "Address",
      editable: false,
      width: 350,
      sortable: false,
    },
    {
      field: "isPrimaryContact",
      headerName: "Contact Type",
      editable: false,
      width: 200,
      sortable: false,
      valueFormatter(params) {
        return params.value ? "Primary" : "Secondary";
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => updateContact(params.row)}>
          <EditTwoToneIcon />
        </IconButton>
      ),
    },
  ];
};
