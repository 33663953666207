import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: "uppercase",
    borderBottom: "1px",
    borderBottomColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
}));
