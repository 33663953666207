import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./Root.store";
import { ISelectOption } from "../Interfaces";
import { finalize, forkJoin, map } from "rxjs";
import { CommonService, ToastService } from "../Services";
import { AxiosError } from "axios";
import { IActionTaskType } from "src/Modules/Company/Interfaces/IActionTaskType";

export class CommonStore {
  rootStore: IRootStore;

  commonDataLoading: boolean;
  actionStatusList: ISelectOption[] = [];
  actionTaskTypeList: IActionTaskType[] = [];
  actionTypeList: ISelectOption[] = [];
  dealStatusList: ISelectOption[] = [];
  leadTypeList: ISelectOption[] = [];
  serviceTypeList: ISelectOption[] = [];
  supportiveRegions: ISelectOption[] = [];
  vendorTypes: ISelectOption[] = [];
  extendVendorTypes: ISelectOption[] = [];
  industries: ISelectOption[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      actionStatusList: observable,
      actionTaskTypeList: observable,
      actionTypeList: observable,
      dealStatusList: observable,
      leadTypeList: observable,
      serviceTypeList: observable,
      supportiveRegions: observable,
      vendorTypes: observable,
      extendVendorTypes: observable,
      industries: observable,
      setcommonDataLoading: action,
      loadCommonData: action,
      setActionTaskTypeList: action,
      setSupportiredRegions: action,
      setActionTypeList: action,
      setActionStatusList: action,
      setLeadTypeList: action,
      setServiceTypeList: action,
      setDealStatusList: action,
      setVendorTypes: action,
      setIndustries: action,
    });
    this.rootStore = rootStore;
  }

  loadCommonData = () => {
    if (
      this.actionStatusList.length > 0 &&
      this.actionTaskTypeList.length > 0 &&
      this.dealStatusList.length > 0 &&
      this.leadTypeList.length > 0 &&
      this.serviceTypeList.length > 0 &&
      this.supportiveRegions.length > 0 &&
      this.vendorTypes.length > 0 &&
      this.industries.length > 0
    ) {
      return;
    }

    this.setcommonDataLoading(true);
    this.rootStore.uiStore.setGlobalLoader(true);
    forkJoin([
      CommonService.getActionStatusList(),
      CommonService.getActionTaskTypeList(),
      CommonService.getActionTypeList(),
      CommonService.getDealStatusList(),
      CommonService.getLeadTypeList(),
      CommonService.getServiceTypeList(),
      CommonService.getSupportiveRegions(),
      CommonService.getVendorTypes(),
      CommonService.getIndustries(),
    ])
      .pipe(
        finalize(() => {
          this.setcommonDataLoading(false);
          this.rootStore.uiStore.setGlobalLoader(false);
        }),
        map(
          ([
            actionStatusList,
            actionTaskTypeList,
            actionTypeList,
            dealStatusList,
            leadTypeList,
            serviceTypeList,
            supportiveRegions,
            vendorTypes,
            industries,
          ]) => ({
            actionStatusList,
            actionTaskTypeList,
            actionTypeList,
            dealStatusList,
            leadTypeList,
            serviceTypeList,
            supportiveRegions,
            vendorTypes,
            industries,
          })
        )
      )
      .subscribe({
        next: (value) => {
          this.setActionStatusList(value.actionStatusList);
          this.setActionTaskTypeList(value.actionTaskTypeList);
          this.setActionTypeList(value.actionTypeList);
          this.setDealStatusList(value.dealStatusList);
          this.setLeadTypeList(value.leadTypeList);
          this.setServiceTypeList(value.serviceTypeList);
          this.setVendorTypes(value.vendorTypes);
          this.setIndustries(value.industries);
          this.setSupportiredRegions(value.supportiveRegions);
        },
        error: (error: AxiosError) =>
          ToastService.error(error.response.data as string),
      });
  };

  setcommonDataLoading = (flag: boolean) => {
    this.commonDataLoading = flag;
  };

  setActionStatusList = (data: ISelectOption[]) => {
    this.actionStatusList = data;
  };
  setActionTaskTypeList = (data: IActionTaskType[]) => {
    this.actionTaskTypeList = data;
  };
  setActionTypeList = (data: ISelectOption[]) => {
    this.actionTypeList = data;
  };
  setDealStatusList = (data: ISelectOption[]) => {
    this.dealStatusList = data;
  };
  setLeadTypeList = (data: ISelectOption[]) => {
    this.leadTypeList = data;
  };
  setServiceTypeList = (data: ISelectOption[]) => {
    this.serviceTypeList = data;
  };
  setVendorTypes = (data: ISelectOption[]) => {
    this.vendorTypes = data;
    this.setextendVendorType(data);
  };

  setextendVendorType = (data: ISelectOption[]) => {
    const extraVendorTypes: ISelectOption[] = [
      { label: "All TDDA Customers", value: 3 },
      { label: "All Health Tick Customers", value: 4 },
    ];
    const combineVendorsType = [...data, ...extraVendorTypes];
    this.extendVendorTypes = combineVendorsType;
  };
  setIndustries = (data: ISelectOption[]) => {
    this.industries = data;
  };
  setSupportiredRegions = (data: ISelectOption[]) => {
    this.supportiveRegions = data;
  };
}
