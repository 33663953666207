import { Box } from "@mui/material";
import ModalContent from "../ModalContent/ModalContent";
import { useModal } from "src/Shared/Hooks";
import { NavigateFunction } from "react-router-dom";

type Props = {
  navigate: NavigateFunction;
};

const WarningPopup: React.FC<Props> = ({ navigate }) => {
  const onConfirm = () => {
    close();
    navigate(-1);
  };
  const { close } = useModal();
  return (
    <>
      <Box>
        <ModalContent
          maxWidth="xs"
          title="Warning!"
          buttonText="Confirm"
          onSaveButtonClick={() => onConfirm()}
          cancelButton={true}
          onCancel={() => close()}
        >
          <Box>
            Are you sure you want to leave. Leaving the page will result in lost
            data?
          </Box>
        </ModalContent>
      </Box>
    </>
  );
};

export default WarningPopup;
