export const fields = (updateTaskTypeList) => {
  return {
    companyActionID: {},
    actionDescription: {
      label: "Description",
      rules: "required|string",
    },
    actionTypeID: {
      label: "Action Type",
      rules: "required",
      observers: [
        {
          key: "value",
          call: ({ form, field }) => {
            updateTaskTypeList(field.value);
          },
        },
      ],
    },
    actionTaskTypeID: {
      label: "Sales Pipeline Action Type",
      rules: "required",
    },
    assignedTo: {
      label: "Sales Pipeline Action Assigned to",
      rules: "required",
      value: {
        label: "",
        value: "",
      },
    },
    franchiseID: {
      label: "Supporting Region",
      rules: "required",
    },
    taskDueDate: {
      label: "Sales Pipeline Action Due",
      rules: "required",
    },
    createdBy: {
      label: "Created By",
    },
    createdOn: {
      label: "Created On",
    },
  };
};
