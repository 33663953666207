import { FormatAlignJustify } from "@mui/icons-material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  addSales: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "20px",
  },
}));
