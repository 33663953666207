import { useEffect, useState } from "react";
import { CustomDataGrid } from "src/Shared/Components";
import { useModal, useStore } from "src/Shared/Hooks";
import { IPageRequest } from "src/Shared/Interfaces";
import { IUserActionsFilter } from "../../Interfaces/IUserActionsFilter";
import { observer } from "mobx-react";
import { IRouteParams, ISalesAction } from "src/Modules/Company/Interfaces";
import { GridPaginationModel } from "@mui/x-data-grid";
import { taskViewColumns } from "./MyActionsGrid.column";
import AddOrEditAction from "src/Modules/Company/Components/AddOrEditAction/AddOrEditAction";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import AddOrEditNotes from "src/Modules/Company/Components/AddOrEditNotes/AddOrEditNotes";
import { useParams } from "react-router-dom";
import { PicUp_Component } from "src/Shared/Enums/PicUpComponent";

const MyActionsGrid: React.FC = () => {
  const {
    rootStore: {
      myTasksStore: {
        myTasksList,
        gridLoading,
        getMyTasksGridList,
        refreshMyTaskGridList,
      },
    },
  } = useStore();

  const modal = useModal();

  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const { companyRowID } = useParams<Partial<IRouteParams>>();
  const [usersFilter, setCompanyFilter] = useState<IUserActionsFilter>({
    userEmail: "",
  });

  const [request, setRequest] = useState<IPageRequest<IUserActionsFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      userEmail: "",
    },
    sortCollection: [],
  });

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: usersFilter,
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  const editSalesAction = (saleAction: ISalesAction) => {
    modal.open(
      <AddOrEditAction
        mode={VIEW_MODE.EDIT}
        saleAction={saleAction}
        picupComponent={PicUp_Component.MyActions}
      />
    );
  };

  useEffect(() => {
    getMyTasksGridList(request);
  }, [request]);

  const addNotes = (saleAction: ISalesAction) => {
    modal.open(<AddOrEditNotes saleAction={saleAction}></AddOrEditNotes>);
  };

  return (
    <>
      <CustomDataGrid
        loading={gridLoading}
        rows={myTasksList.results || []}
        columns={taskViewColumns(editSalesAction, addNotes)}
        rowCount={myTasksList.totalNumberOfRecords || 0}
        getRowId={(row: ISalesAction) => row.companyActionID}
        style={{
          height: "calc(100vh - 140px)",
        }}
        paginationModel={gridPagination}
        onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
        onRowDoubleClick={(params) => editSalesAction(params.row)}
      />
    </>
  );
};

export default observer(MyActionsGrid);
