import { Box, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { getFormValidation } from "src/Shared/Components";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { fields } from "./fields";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import { observer } from "mobx-react";
import { useStyles } from "./AddOrEditCompany.styles";
import Title from "src/Shared/Components/Title/Title";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { ICompany, IRouteParams } from "../../Interfaces";
import { Link, useNavigate, useParams } from "react-router-dom";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import CompanyService from "../../Services/Company.service";
import { finalize } from "rxjs";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import { IResult } from "src/Shared/Interfaces";
import AddComments from "../AddComments/AddComments";
import { useModal } from "src/Shared/Hooks";
import DateTimeUtils from "src/Shared/Tools/DateTimeUtils";

const AddOrEditCompany: React.FC = () => {
  const modal = useModal();
  const companyForm = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => companyForm.$(key);
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    rootStore: {
      companyStore: {
        company,
        getCompany,
        resetCompanyList,
        getArchivedCompany,
        archivedCompany,
      },
      commonStore: {
        supportiveRegions,
        vendorTypes,
        industries,
        leadTypeList,
        serviceTypeList,
        loadCommonData,
      },
      uiStore,
    },
  } = useStore();

  const [title, setTitle] = useState<string>("Add New Client");
  const [buttonText, setbuttonText] = useState<string>("Add Company");

  const { mode, companyRowID } = useParams<Partial<IRouteParams>>();

  useEffect(() => {
    loadCommonData();
    switch (mode) {
      case VIEW_MODE.ADD:
        {
          setTitle("Add New Client");
        }
        break;
      case VIEW_MODE.EDIT:
        {
          setbuttonText("Update Company");
          getCompany(companyRowID);
        }
        break;
      case VIEW_MODE.Archived:
        {
          setbuttonText("Transfer Company");
          setTitle("Archived Client");
          getArchivedCompany(Number(companyRowID));
        }
        break;
    }
  }, []);

  useEffect(() => {
    if (company && mode == VIEW_MODE.EDIT) {
      setTitle(`${company.companyName} - Edit`);
      companyForm.set(company);
    }
  }, [company]);

  useEffect(() => {
    if (archivedCompany && mode == VIEW_MODE.Archived) {
      if (archivedCompany.serviceTypeIDs == null) {
        archivedCompany.serviceTypeIDs = [];
      }
      companyForm.set(archivedCompany);
      companyForm.showErrors();
      // companyForm.validate();
    }
  }, [archivedCompany]);

  const gridProps: IGridFormControlProps = {
    row: 2,
    col: 9,
    spacing: 4,
    itemResponsive: {
      lg: 6,
      md: 6,
      sm: 12,
    },
    inputControls: [
      {
        field: getField("companyName"),
        fieldKey: "companyName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("franchiseID"),
        fieldKey: "franchiseID",
        type: EDITOR_TYPES.AUTO_COMPLETE,
        options: supportiveRegions,
      },
      {
        field: getField("companyType"),
        fieldKey: "companyType",
        type: EDITOR_TYPES.RADIO_BUTTON,
        options: [
          {
            label: "National",
            value: "National",
          },
          {
            label: "Regional",
            value: "Regional",
          },
        ],
      },

      {
        field: getField("vendorTypeIDs"),
        fieldKey: "vendorTypeIDs",
        type: EDITOR_TYPES.DROPDOWN,
        multiple: true,
        options: vendorTypes,
      },
      {
        field: getField("industryID"),
        fieldKey: "industryID",
        type: EDITOR_TYPES.DROPDOWN,
        options: industries,
      },
      {
        field: getField("website"),
        fieldKey: "website",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("totalStaffNumber"),
        fieldKey: "totalStaffNumber",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("leadTypeID"),
        fieldKey: "leadTypeID",
        type: EDITOR_TYPES.DROPDOWN,
        options: leadTypeList,
      },
      {
        field: getField("serviceTypeIDs"),
        fieldKey: "serviceTypeIDs",
        type: EDITOR_TYPES.DROPDOWN,
        multiple: true,
        options: serviceTypeList,
      },
      {
        field: getField("isSLASigned"),
        fieldKey: "isSLASigned",
        type: EDITOR_TYPES.SWITCH,
      },
      {
        field: getField("slaExpiryDate"),
        fieldKey: "slaExpiryDate",
        type: EDITOR_TYPES.DATE,
      },
      {
        field: getField("primaryCompanyRelationshipManager"),
        fieldKey: "primaryCompanyRelationshipManager",
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
      },
      {
        field: getField("additionalCompanyRelationshipManagers"),
        fieldKey: "additionalCompanyRelationshipManagers",
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
        multiple: true,
      },
      {
        field: getField("attachments"),
        fieldKey: "attachments",
        multiple: true,
        type: EDITOR_TYPES.FILE_UPLOADER,
      },
      {
        field: getField("salesTotalValue"),
        fieldKey: "salesTotalValue",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
      {
        field: getField("dealStage"),
        fieldKey: "dealStage",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
      {
        field: getField("createdByOn"),
        fieldKey: "createdByOn",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
      {
        field: getField("modifiedByOn"),
        fieldKey: "modifiedByOn",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
    ],
    form: companyForm,
  };

  const onFromSubmit = () => {
    if (!companyForm.isValid) {
      companyForm.validate();
      companyForm.showErrors();
      return;
    }
    const request: ICompany = { ...companyForm.values() };
    request.slaExpiryDate =
      request.slaExpiryDate == ""
        ? null
        : DateTimeUtils.toApiDate(request.slaExpiryDate);
    switch (mode) {
      case VIEW_MODE.ADD:
        uiStore.setGlobalLoader(true);
        CompanyService.addCompany(request)
          .pipe(
            finalize(() => {
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              resetCompanyList();
              ToastService.success(data.message);
              navigate(`/company/${data.content}/addContacts`);
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
      case VIEW_MODE.EDIT:
        uiStore.setGlobalLoader(true);
        CompanyService.updateCompany(request)
          .pipe(
            finalize(() => {
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              resetCompanyList();
              ToastService.success(data.message);
              navigate(`/company`);
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
      case VIEW_MODE.Archived:
        uiStore.setGlobalLoader(true);
        request.archivedCRMDataID = Number(companyRowID);
        request.isArchived = true;
        CompanyService.addCompany(request)
          .pipe(
            finalize(() => {
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              resetCompanyList();
              ToastService.success(data.message);
              navigate(`/company`);
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
    }
  };

  const handleOpen = () => {
    modal.open(<AddComments companyRowID={companyRowID} />);
  };

  return (
    <>
      <MainContentBox>
        <Box className={classes.viewButtons}>
          <Title backButtonEnabled warningRequired title={title}></Title>
          {mode == VIEW_MODE.EDIT && (
            <Box>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                component={Link}
                to={`/company/${companyRowID}/contacts`}
              >
                View/Edit Contacts
              </Button>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                component={Link}
                to={`/company/${companyRowID}/offices`}
              >
                View/Edit Offices
              </Button>
              <Button variant="contained" onClick={handleOpen}>
                View/Edit Comments
              </Button>
            </Box>
          )}
        </Box>
        <Box className={classes.root}>
          <GridFormControl {...gridProps} />
        </Box>
        <Box className={classes.submitButtonBox}>
          <Button variant="contained" onClick={onFromSubmit}>
            {buttonText}
          </Button>
        </Box>
      </MainContentBox>
    </>
  );
};

export default observer(AddOrEditCompany);
