import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  filterBox: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
  },
  buttonBox: {
    display: "flex",
    justifyContent: "end",
  },
  optionBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));
