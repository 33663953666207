import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { useModal } from "src/Shared/Hooks";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide timeout={{
    enter:800,
    exit:800,
  }} direction="left" ref={ref} {...props} />;
});

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "95%",
    height: "100%",
    maxHeight: "100%",
    maxWidth: "100%",
    margin: "unset",
  },
  "& .MuiDialog-container": {
    justifyContent: "end",
  },
}));

type Props = {
  children?: React.ReactNode;
  title?: string;
};

const FullScreenModal: React.FC<Props> = ({ children, title }) => {
  const { close } = useModal();

  return (
    <FullScreenDialog
      open
      onClose={() => close()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => close()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>{children}</DialogContent>
    </FullScreenDialog>
  );
};

export default FullScreenModal;
