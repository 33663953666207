const baseCompanyApiUrls = "/api/Company";
export const CompanyApiUrls = {
  GetCompanies: `${baseCompanyApiUrls}/GetCompanies`,
  AddCompany: `${baseCompanyApiUrls}/AddCompany`,
  UpdateCompany: `${baseCompanyApiUrls}/UpdateCompany`,
  GetCompanyOverview: `${baseCompanyApiUrls}/OverView`,
  GetArchivedCompany: `${baseCompanyApiUrls}/ArchivedCompany`,
  GetCompany: (companyRowID: string) =>
    `${baseCompanyApiUrls}?companyRowID=${companyRowID}`,
  SaveComment: `${baseCompanyApiUrls}/Comment`,
  GetComment: `${baseCompanyApiUrls}/Comment`,
  Search: (companyName: string, totalCount: number) =>
    `${baseCompanyApiUrls}/Search?companyName=${companyName}&totalCount=${totalCount}`,
};
