import { useEffect, useState } from "react";
import { CustomDataGrid } from "src/Shared/Components";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useStyles } from "./SalesAction.styles";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IPageRequest } from "src/Shared/Interfaces";
import { ISalesDealFilter } from "../../Interfaces/ISalesDealFilter";
import { useParams } from "react-router-dom";
import { IRouteParams, ISalesAction } from "../../Interfaces";
import { observer } from "mobx-react";
import { useModal } from "src/Shared/Hooks";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import AddOrEditAction from "../AddOrEditAction/AddOrEditAction";
import { assignedColumns } from "./SalesAction.column";
import AddOrEditNotes from "../AddOrEditNotes/AddOrEditNotes";

const SalesAction: React.FC = () => {
  const {
    rootStore: {
      salesActionStore: {
        gridLoading,
        salesActionList,
        getSalesActionGridList,
        refreshSalesActionGridList,
      },
    },
  } = useStore();

  const classes = useStyles();
  const modal = useModal();
  const { companyRowID } = useParams<Partial<IRouteParams>>();
  const addNewAction = () => {
    modal.open(
      <AddOrEditAction mode={VIEW_MODE.ADD} companyRowID={companyRowID} />
    );
  };

  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [companyFilter, setCompanyFilter] = useState<ISalesDealFilter>({
    companyRowID: companyRowID,
  });

  const [request, setRequest] = useState<IPageRequest<ISalesDealFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      companyRowID: companyRowID,
    },
    sortCollection: [],
  });

  const editSalesAction = (saleAction: ISalesAction) => {
    modal.open(
      <AddOrEditAction
        mode={VIEW_MODE.EDIT}
        saleAction={saleAction}
        companyRowID={companyRowID}
      />
    );
  };

  const addNotes = (saleAction: ISalesAction) => {
    modal.open(
      <AddOrEditNotes
        saleAction={saleAction}
        companyRowID={companyRowID}
      ></AddOrEditNotes>
    );
  };

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: companyFilter,
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  useEffect(() => {
    getSalesActionGridList(request);
  }, [request]);

  return (
    <>
      <div className={classes.addSales}>
        <Button variant="contained" onClick={addNewAction}>
          <AddCircleIcon />
          Add Action
        </Button>
      </div>
      <CustomDataGrid
        loading={gridLoading}
        columns={assignedColumns(editSalesAction, addNotes)}
        rowSelection={false}
        rows={salesActionList.results || []}
        rowCount={salesActionList.totalNumberOfRecords || 0}
        rowHeight={70}
        style={{
          height: "calc(100vh - 255px)",
        }}
        paginationModel={gridPagination}
        getRowId={(row: ISalesAction) => row.companyActionID}
        onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
        onRowDoubleClick={(params) => editSalesAction(params.row)}
      />
    </>
  );
};

export default observer(SalesAction);
