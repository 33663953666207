import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { ISalesAction } from "../../Interfaces";
import { useModal, useStore } from "src/Shared/Hooks";
import { getFormValidation } from "src/Shared/Components";
import { fields } from "./fields";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { IResult } from "src/Shared/Interfaces";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import { finalize, forkJoin, map } from "rxjs";
import SalesActionService from "../../Services/SalesAction.service";
import { IActionTaskType } from "../../Interfaces/IActionTaskType";
import { ActionStatus, ActionType } from "../../Enums";
import { PicUp_Component } from "src/Shared/Enums/PicUpComponent";
import { IActionNote } from "../../Interfaces/IActionNote";
import AddNoteConfirmation from "src/Shared/Components/AddNoteConfirmation/AddNoteConfirmation";
import { IActionStatusRequest } from "../../Interfaces/IActionStatusRequest";
import DateTimeUtils from "src/Shared/Tools/DateTimeUtils";
import moment from "moment";

type Props = {
  mode: VIEW_MODE;
  saleAction?: ISalesAction;
  companyRowID?: string;
  picupComponent?: PicUp_Component;
};

const AddOrEditAction: FC<Props> = ({
  mode,
  saleAction,
  companyRowID,
  picupComponent,
}) => {
  const {
    rootStore: {
      commonStore: {
        actionTypeList,
        actionTaskTypeList,
        actionStatusList,
        loadCommonData,
        supportiveRegions,
      },
      salesActionStore: {
        isNoteBtnLoading,
        setNoteBtnLoading,
        refreshSalesActionGridList,
      },
      myTasksStore: {
        refreshMyTaskGridList,
        refreshTaskAssignedByUserGridList,
      },
      uiStore,
    },
  } = useStore();

  const modal = useModal();
  const updateTaskTypeList = (value: ActionType) => {
    setactionTaskTypeFilteredList(
      actionTaskTypeList.filter((x) => x.actionTypeID == value)
    );

    let actionTypeField = getField("actionTaskTypeID");
    let assignedToField = getField("assignedTo");
    let taskDueDateField = getField("taskDueDate");

    switch (value) {
      case ActionType.CRMAction:
        actionTypeField.set("label", "CRM Action Type");
        assignedToField.set("label", "CRM Action Assigned to");
        taskDueDateField.set("label", "CRM Action Due");
        break;
      case ActionType.SalesPipelineAction:
        actionTypeField.set("label", "Sales Pipeline Action Type");
        assignedToField.set("label", "Sales Pipeline Action Assigned to");
        taskDueDateField.set("label", "Sales Pipeline Action Due");
        break;
    }
  };
  const [buttonText, setbuttonText] = useState<string>("Add");
  const [tittle, settittle] = useState<string>("Add Action");
  const [actionTaskTypeFilteredList, setactionTaskTypeFilteredList] = useState<
    IActionTaskType[]
  >([]);
  const saleActionForm = useMemo(
    () => getFormValidation(fields(updateTaskTypeList)),
    [actionTaskTypeList]
  );

  const getField = (key: string) => saleActionForm.$(key);

  useEffect(() => {
    loadCommonData();
    switch (mode) {
      case VIEW_MODE.EDIT:
        {
          setbuttonText("Update");
          settittle("Edit Action");
        }
        break;
    }
  }, []);

  const reload = () => {
    modal.close();
    modal?.close();
    switch (picupComponent) {
      case PicUp_Component.MyActions:
        refreshMyTaskGridList();
        break;
      case PicUp_Component.ActionAssignedByUser:
        refreshTaskAssignedByUserGridList();
        break;
      default:
        refreshSalesActionGridList(companyRowID);
        break;
    }
  };

  const isTaskTypeSelected = (actionTaskType): boolean => {
    if (mode == VIEW_MODE.EDIT) return false;
    else {
      return !!actionTaskType && mode == VIEW_MODE.ADD ? false : true;
    }
  };

  useEffect(() => {
    if (saleAction && mode == VIEW_MODE.EDIT) {
      saleActionForm.set(saleAction);
    }
  }, [saleAction]);

  const [loading, setLoading] = useState<boolean>(false);

  const gridPropsForStaticData: IGridFormControlProps = {
    row: 2,
    col: 1,
    itemResponsive: {
      lg: 6,
      sm: 12,
      md: 4,
    },
    inputControls: [
      {
        field: getField("createdBy"),
        fieldKey: "createdBy",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
      {
        field: getField("createdOn"),
        fieldKey: "createdOn",
        type: EDITOR_TYPES.TEXT_FIELD,
        isEditingMode: false,
        hidden: mode == VIEW_MODE.ADD,
      },
    ],
    form: saleActionForm,
  };

  const gridPropsForActions: IGridFormControlProps = {
    row: 1,
    col: 9,
    itemResponsive: {
      lg: 12,
      sm: 12,
      md: 4,
    },
    inputControls: [
      {
        field: getField("actionDescription"),
        fieldKey: "actionDescription",
        type: EDITOR_TYPES.TEXT_FIELD,
        multiline: true,
        rows: 3,
        hidden: isTaskTypeSelected(getField("actionTypeID").value),
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
      },
      {
        field: getField("actionTypeID"),
        fieldKey: "actionTypeID",
        type: EDITOR_TYPES.DROPDOWN,
        options: actionTypeList,
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
      },
      {
        field: getField("actionTaskTypeID"),
        fieldKey: "actionTaskTypeID",
        type: EDITOR_TYPES.DROPDOWN,
        options: actionTaskTypeFilteredList,
        hidden: isTaskTypeSelected(getField("actionTypeID").value),
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
      },
      {
        field: getField("assignedTo"),
        fieldKey: "assignedTo",
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
        hidden: isTaskTypeSelected(getField("actionTypeID").value),
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
      },
      {
        field: getField("franchiseID"),
        fieldKey: "franchiseID",
        type: EDITOR_TYPES.AUTO_COMPLETE,
        options: supportiveRegions,
        hidden: isTaskTypeSelected(getField("actionTypeID").value),
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
      },
      {
        field: getField("taskDueDate"),
        fieldKey: "taskDueDate",
        type: EDITOR_TYPES.DATE,
        hidden: isTaskTypeSelected(getField("actionTypeID").value),
        inputProps: {
          readOnly:
            mode == VIEW_MODE.EDIT &&
            saleAction.actionStatusID != ActionStatus.Completed,
        },
        minDate: moment.utc(new Date()),
      },
    ],
    form: saleActionForm,
  };

  const onFromSubmit = () => {
    if (!saleActionForm.isValid) {
      saleActionForm.showErrors();
      return;
    }
    const request: ISalesAction = { ...saleActionForm.values() };
    switch (mode) {
      case VIEW_MODE.ADD:
        setNoteBtnLoading(true);
        request.actionStatusID = request.actionStatusID
          ? request.actionStatusID
          : null;
        request.companyRowID = companyRowID;
        SalesActionService.addSalesAction(request)
          .pipe(
            finalize(() => {
              setNoteBtnLoading(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
      case VIEW_MODE.EDIT:
        modal.open(
          <AddNoteConfirmation
            updateAction={(val) => updateAction(val)}
            title={"Add Note"}
          />
        );
        break;
    }
  };

  const onActionStatusChange = () => {
    if (mode == VIEW_MODE.EDIT) {
      modal.open(
        <AddNoteConfirmation
          content={"Are you sure you want to complete this action?"}
          updateAction={(val) => updateAction(val)}
          isMarkAsCompleted={true}
          title={"Add Note"}
        />
      );
    }
  };

  const updateAction = (noteRequest: IActionNote) => {
    const salesActionRequest: ISalesAction = { ...saleActionForm.values() };
    salesActionRequest.note = noteRequest.note;
    setNoteBtnLoading(true);
    if (noteRequest.isMarkAsCompleted) {
      let request: IActionStatusRequest = {
        companyActionID: saleAction.companyActionID,
        note: noteRequest.note,
      };
      SalesActionService.setActionStatusCompleted(request)
        .pipe(
          finalize(() => {
            setNoteBtnLoading(false);
          })
        )
        .subscribe({
          next: (value) => {
            ToastService.success(value.message);
            reload();
          },
          error: (error: AxiosError) =>
            ToastService.error(error.response.data as string),
        });
    } else {
      salesActionRequest.taskDueDate = DateTimeUtils.toApiDate(salesActionRequest.taskDueDate);
      SalesActionService.updateSalesAction(salesActionRequest)
        .pipe(
          finalize(() => {
            setNoteBtnLoading(false);
          })
        )
        .subscribe({
          next: (value) => {
            ToastService.success(value.message);
            reload();
          },
          error: (error: AxiosError) =>
            ToastService.error(error.response.data as string),
        });
    }
  };

  return (
    <ModalContent
      onSaveButtonClick={onFromSubmit}
      buttonText={buttonText}
      title={tittle}
      buttonDisabled={
        mode == VIEW_MODE.EDIT &&
        saleAction.actionStatusID != ActionStatus.Completed
      }
      btnLoading={isNoteBtnLoading}
      cancelButton={
        mode == VIEW_MODE.EDIT &&
        saleAction.actionStatusID != ActionStatus.Completed
      }
      cancelButtonText={"Mark as completed"}
      cancelButtonProps={{
        variant: "contained",
      }}
      onCancel={onActionStatusChange}
    >
      <GridFormControl {...gridPropsForActions} />
      <GridFormControl {...gridPropsForStaticData} />
    </ModalContent>
  );
};

export default observer(AddOrEditAction);
