import { Observable } from "rxjs";
import { IPageRequest, IPagedResult, IResult } from "src/Shared/Interfaces";
import { ICompanyOffice } from "../Interfaces/ICompanyOffice";
import HttpClient from "src/Shared/Tools/HttpClient";
import { CompanyOfficeApiUrl } from "../Tools/CompanyOfficeApiUrls";
import { IOfficeFilter } from "../Interfaces/IOfficeFilter";
import { IAddCompanySites } from "../Interfaces";
import { BaseService } from "src/Shared/Services/Base.service";

class CompanyOfficeService extends BaseService {
  public getCompanyOfficesList(
    request: IPageRequest<IOfficeFilter>
  ): Observable<IPagedResult<ICompanyOffice>> {
    return this.http.post<IPagedResult<ICompanyOffice>>(
      // CompanyOfficeApiUrl.GetOffices + `?companyRowID=${request.filterCollection.companyRowID}`
      CompanyOfficeApiUrl.GetOffices,
      request
    );
  }

  public addCompanySite(request: ICompanyOffice[]) {
    return this.http.post<IResult<string>>(
      CompanyOfficeApiUrl.AddOffices,
      request
    );
  }

  public updateOffice(request: ICompanyOffice) {
    return this.http.post<IResult<string>>(
      CompanyOfficeApiUrl.UpdateOffices,
      request
    );
  }

  public getPrimaryOfficeID(companyRowID: string) {
    return this.http.get<number | null>(
      CompanyOfficeApiUrl.GetPrimaryOfficeID(companyRowID)
    );
  }
}

export default new CompanyOfficeService();
