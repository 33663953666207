import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import MyActionsGrid from "./Components/MyActionsGrid/MyActionsGrid";
import ActionTabs from "./Components/ActionTabs/ActionTabs";
import ActionAssignedByUserGrid from "./Components/ActionAssignedByUserGrid/ActionAssignedByUserGrid";

const MyActionModule = () => {
  return (
    <>
      <Box
        sx={{
          m: 1,
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to={"assigned-to-me"} />}></Route>

          <Route path="/" element={<ActionTabs />}>
            <Route path="assigned-to-me" element={<MyActionsGrid />}></Route>
            <Route
              path="assigned-by-me"
              element={<ActionAssignedByUserGrid />}
            ></Route>
          </Route>
        </Routes>
      </Box>
    </>
  );
};

export default MyActionModule;
