import { Box, Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import Title from "src/Shared/Components/Title/Title";
import { useStyles } from "./CompanyOfficeGrid.styles";
import { CustomDataGrid } from "src/Shared/Components";
import { officeColumns } from "./CompanyOfficeGrid.column";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { IPageRequest } from "src/Shared/Interfaces";
import { useParams } from "react-router-dom";
import { ICompanyOffice, IRouteParams } from "../../Interfaces";
import { observer } from "mobx-react";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IOfficeFilter } from "../../Interfaces/IOfficeFilter";
import { useModal } from "src/Shared/Hooks";
import OfficeForm from "../AddOrEditOffices/AddOrEditOffice";
import AddOrEditOffice from "../AddOrEditOffices/AddOrEditOffice";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";

const CompanyOfficeGrid: React.FC = () => {
  const {
    rootStore: {
      companyOfficeStore: {
        gridLoading,
        companyOfficeList,
        getCompanyOffices,
        primaryOfficeID,
      },
    },
  } = useStore();

  const classes = useStyles();
  const modal = useModal();

  const { companyRowID } = useParams<Partial<IRouteParams>>();
  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };
  const [request, setRequest] = useState<IPageRequest<IOfficeFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      companyRowID: companyRowID,
    },
    sortCollection: [],
  });

  const updateOffice = (companyOffice: ICompanyOffice) => {
    modal.open(
      <AddOrEditOffice
        mode={VIEW_MODE.EDIT}
        companyOffice={companyOffice}
        companyRowID={companyRowID}
        primayOfficeId={primaryOfficeID}
      />
    );
  };

  useEffect(() => {
    getCompanyOffices(request);
  }, [request]);

  const handleOpen = () => {
    modal.open(
      <AddOrEditOffice
        mode={VIEW_MODE.ADD}
        companyRowID={companyRowID}
        primayOfficeId={primaryOfficeID}
      />
    );
  };

  return (
    <>
      <MainContentBox>
        <Title warningRequired title="Client Offices"></Title>

        <Box className={classes.root}>
          <Button variant="contained" onClick={handleOpen}>
            Add Offices
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          <CustomDataGrid
            loading={gridLoading}
            columns={officeColumns(updateOffice)}
            rows={companyOfficeList.results || []}
            rowCount={companyOfficeList.totalNumberOfRecords || 0}
            rowSelection={false}
            getRowId={(row: ICompanyOffice) => row.companySiteID}
            style={{
              height: "calc(100vh - 245px)",
            }}
            paginationModel={gridPagination}
            onPaginationModelChange={(model, _) =>
              onGridPaginationChange(model)
            }
            onRowDoubleClick={(params) => updateOffice(params.row)}
          />
        </Box>
      </MainContentBox>
    </>
  );
};

export default observer(CompanyOfficeGrid);
