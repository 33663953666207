import { ThemeOptions, createTheme } from "@mui/material";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#EE7600",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "#304B73",
    },
  },
  typography: {
    fontFamily: "Titillium Web",
  },
};

export const TddaTheme = createTheme(themeOptions);
