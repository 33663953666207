import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomDataGrid } from "src/Shared/Components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { salesColumns } from "./SaleDeal.coloumn";
import { useStyles } from "./SaleDeal.styles";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IPageRequest } from "src/Shared/Interfaces";
import { ISalesDealFilter } from "../../Interfaces/ISalesDealFilter";
import { IRouteParams, ISalesDeal } from "../../Interfaces";
import { observer } from "mobx-react";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { useParams } from "react-router-dom";
import AddOrEditSaleDeal from "../AddOrEditSaleDeal/AddOrEditSaleDeal";
import { useModal } from "src/Shared/Hooks";
import SaleDealHistory from "../SaleDealHistory/SaleDealHistory";

const SalesDeal: React.FC = () => {
  const {
    rootStore: {
      salesDealStore: {
        gridLoading,
        salesDealList,
        getSalesGridList,
        refreshSalesGridList,
        setsaleDealDialog,
        saleDealDialog,
      },
    },
  } = useStore();

  const modal = useModal();

  const addNewSalesDeal = () => {
    modal.open(
      <AddOrEditSaleDeal mode={VIEW_MODE.ADD} companyRowID={companyRowID} />
    );
  };

  const editSalesDeals = (saleDeal: ISalesDeal) => {
    modal.open(
      <AddOrEditSaleDeal
        mode={VIEW_MODE.EDIT}
        saleDeal={saleDeal}
        companyRowID={companyRowID}
      />
    );
  };

  const openSalesDealHistory = (saleDeal: ISalesDeal) => {
    modal.open(<SaleDealHistory saleDeal={saleDeal} />);
  };

  const { companyRowID } = useParams<Partial<IRouteParams>>();

  const classess = useStyles();
  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [request, setRequest] = useState<IPageRequest<ISalesDealFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      companyRowID: companyRowID,
    },
    sortCollection: [],
  });

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  useEffect(() => {
    getSalesGridList(request);
  }, [request]);

  return (
    <>
      <div className={classess.addSales}>
        <Button variant="contained" onClick={addNewSalesDeal}>
          <AddCircleIcon />
          Add a Sales Deal
        </Button>
      </div>
      <CustomDataGrid
        loading={gridLoading}
        columns={salesColumns(editSalesDeals, openSalesDealHistory)}
        rowSelection={false}
        rows={salesDealList.results || []}
        rowCount={salesDealList.totalNumberOfRecords || 0}
        style={{
          height: "calc(100vh - 270px)",
        }}
        paginationModel={gridPagination}
        getRowId={(row: ISalesDeal) => row.salesDealID}
        onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
        onRowDoubleClick={(params) => editSalesDeals(params.row)}
      />
    </>
  );
};

export default observer(SalesDeal);
