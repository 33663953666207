import { Paper, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./MainContentBox.styles";

type Props = {
  children?: React.ReactNode;
};
const MainContentBox: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root} variant="outlined">
        {children}
      </Paper>
    </>
  );
};

export default MainContentBox;
