import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import tddaLogo from "../../../Assets/images/logo2.png";
import { Backdrop, Button, LinearProgress } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FC, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import UserMenu from "../UserMenu/UserMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_URLS } from "src/Shared/Enums/RouteUrls.enum";
import { Tabs } from "./Header.tabs";
import { useStyles } from "./Header.styles";

const Header: FC = () => {
  const {
    rootStore: { authStore, uiStore },
  } = useStore();

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const user = authStore.user;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setanchorEl] = useState<null | Element>(null);
  const handleClick = (event: React.MouseEvent) => {
    setMenuOpen(true);
    setanchorEl(event.currentTarget);
  };

  const handelClose = () => {
    setMenuOpen(false);
  };

  const onLogout = () => {
    authStore.logout();
    return navigate(ROUTE_URLS.LOGIN);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Link to={ROUTE_URLS.HOME}>
            <img
              src={tddaLogo}
              alt="TDDA"
              style={{ height: "63px", marginRight: "10px" }}
            />
          </Link>
          {Tabs.map((val) => {
            return (
              <Button
                key={val.name}
                variant={
                  val.link === location.pathname.split("/")[1]
                    ? "outlined"
                    : "contained"
                }
                disableElevation
                sx={{
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                className={
                  val.link === location.pathname.split("/")[1]
                    ? classes.active
                    : ""
                }
                component={Link}
                to={val.link}
                startIcon={val.icons}
                color={
                  val.link === location.pathname.split("/")[1]
                    ? "secondary"
                    : "primary"
                }
              >
                {val.name}
              </Button>
            );
          })}

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            variant="contained"
            startIcon={<AccountCircleIcon />}
            disableElevation
            sx={{
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
            onClick={handleClick}
          >
            <Typography variant="body1" sx={{ marginLeft: "5px" }}>
              {user?.firstName} {user?.lastName}
            </Typography>
          </Button>
          <UserMenu
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={handelClose}
            onLogout={onLogout}
          />
        </Toolbar>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) =>
              Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
          }}
          open={uiStore.globalLoading}
        ></Backdrop>
        {uiStore.globalLoading && <LinearProgress />}
      </AppBar>
    </div>
  );
};
export default observer(Header);
