import { DependencyList, EffectCallback, useEffect } from "react";
import useTimeout from "./UseTimeout.hook";

export default function useDebounce(
  effect: EffectCallback,
  delay: number,
  deps?: DependencyList
) {
  const { reset, clear } = useTimeout(effect, delay);
  useEffect(reset, [...deps, reset]);
  useEffect(clear, []);
}
