import { action, makeObservable, observable } from "mobx";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { ICompanyContact } from "../Interfaces/ICompanyContact";
import { IContactFilter } from "../Interfaces/IContactFilter";
import { Subscription, finalize, forkJoin, map } from "rxjs";
import CompanyContactService from "../Services/CompanyContact.service";

export class CompanyContactStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;
  public primaryContactID: number | null = null;

  public companyContactList: IPagedResult<ICompanyContact> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      companyContactList: observable,
      setGridLoading: action,
      getCompanyContact: action.bound,
      setCompanyContactList: action,
      refreshCompanyContactList: action,
      primaryContactID: observable,
    });
    this.rootStore = rootStore;
  }
  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  getCompanyContact(request: IPageRequest<IContactFilter>): Subscription {
    this.setGridLoading(true);
    return forkJoin([
      CompanyContactService.getCompanyConatctList(request),
      CompanyContactService.getPrimaryContactID(
        request.filterCollection.companyRowID
      ),
    ])
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe(([listData, primaryContactID]) => {
        if (listData) {
          this.setCompanyContactList(listData);
        }
        this.setPrimaryContactID(primaryContactID);
      });
  }

  refreshCompanyContactList = (companyRowID: string) => {
    this.getCompanyContact({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
    });
  };

  setCompanyContactList(data: IPagedResult<ICompanyContact>) {
    if (data) {
      this.companyContactList = data;
    }
  }

  setPrimaryContactID = (val: number) => {
    this.primaryContactID = val ? val : null;
  };
}
