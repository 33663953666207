import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  chipsBox: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));
