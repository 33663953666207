export const fields = (updateSalesTotalValue) => {
  return {
    salesDealID: {},
    dealDescription: {
      label: "Deal Description",
      type: "text",
      rules: "required|string",
    },
    valueOfTesting: {
      label: "Value of Testing",
      rules: "required",
      value: "0",
      observers: [
        {
          key: "value",
          call: ({ form, field }) => {
            updateSalesTotalValue();
          },
        },
      ],
    },
    valueOfTraining: {
      label: "Value of Training",
      rules: "required",
      value: "0",
      observers: [
        {
          key: "value",
          call: ({ form, field }) => {
            updateSalesTotalValue();
          },
        },
      ],
    },
    valueOfPolicyReview: {
      label: "Value of Policy Review",
      rules: "required",
      value: "0",
      observers: [
        {
          key: "value",
          call: ({ form, field }) => {
            updateSalesTotalValue();
          },
        },
      ],
    },
    valueOfHealthTickServices: {
      label: "Healthtick",
      rules: "required",
      value: "0",

      observers: [
        {
          key: "value",
          call: ({ form, field }) => {
            updateSalesTotalValue();
          },
        },
      ],
    },
    totalValue: {
      label: "Total",
      rules: "required",
      value: "0",
      readonly: true,
    },
    dealStatusID: {
      label: "Deal Stage",
      rules: "required",
    },
  };
};
