export const fields = () => {
  return {
    companyName: {
      label: "Search Client",
      value: null,
      // defaultValue: {
      //   label: "",
      //   value: null,
      // },
    },
    franchiseID: {
      label: "Supporting Region",
      value: [],
    },
    totalStaffNumber: {
      label: "Company Size",
      type: "number",
      rules: "numeric",
      value: null,
    },
    vendorTypeID: {
      label: "TDDA / Health Tick",
      value: null,
    },
    leadTypeIDs: {
      label: "Lead Type",
      value: [],
    },
    services: {
      label: "Services",
      value: [],
    },
    isSLASigned: {
      label: "Signed SLA",
      value: null,
    },
    slaExpiryDate: {
      label: "SLA Expiry",
      value: null,
    },
    dealStage: {
      label: "Deal Stage",
      value: [],
    },
    primaryRelationshipManager: {
      label: "Primary Relationship Manager",
      value: [],
    },
    piplineValue: {
      label: "Pipline Value",
      value: {
        min: null,
        max: null,
      },
    },
    inProgressActions: {
      label: "In Progress actions",
      value: null,
    },
    overdueActions: {
      label: "Overdue Actions",
      value: null,
    },
    createdBy: {
      label: "Created By",
      value: [],
    },
    modifiedBy: {
      label: "Modified By",
      value: {
        label: "",
        value: "",
      },
      defaultValue: {
        label: "",
        value: "",
      },
    },
  };
};
