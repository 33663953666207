import {
  CommentStore,
  CompanyContactStore,
  CompanyStore,
  SalesActionStore,
  SalesDealStore,
} from "src/Modules/Company/Stores";
import { AuthStore } from "./Auth.store";
import { UIStore } from "./UI.store";
import { CommonStore } from "./Common.store";
import { CompanyOfficeStore } from "src/Modules/Company/Stores/CompanyOffice.store";
import { MyActionsStore } from "src/Modules/MyActions/Stores/MyActions.store";

export interface IRootStore {
  authStore: AuthStore;
  uiStore: UIStore;
  companyStore: CompanyStore;
  salesDealStore: SalesDealStore;
  salesActionStore: SalesActionStore;
  companyOfficeStore: CompanyOfficeStore;
  companyContactStore: CompanyContactStore;
  addCommentStore: CommentStore;
  myTasksStore: MyActionsStore;
}

export class RootStore implements IRootStore {
  authStore: AuthStore;
  uiStore: UIStore;
  companyStore: CompanyStore;
  commonStore: CommonStore;
  salesDealStore: SalesDealStore;
  salesActionStore: SalesActionStore;
  companyOfficeStore: CompanyOfficeStore;
  companyContactStore: CompanyContactStore;
  addCommentStore: CommentStore;
  myTasksStore: MyActionsStore;

  constructor() {
    this.authStore = new AuthStore();
    this.uiStore = new UIStore();
    this.companyStore = new CompanyStore(this);
    this.commonStore = new CommonStore(this);
    this.salesDealStore = new SalesDealStore(this);
    this.salesActionStore = new SalesActionStore(this);
    this.companyOfficeStore = new CompanyOfficeStore(this);
    this.companyContactStore = new CompanyContactStore(this);
    this.addCommentStore = new CommentStore(this);
    this.myTasksStore = new MyActionsStore(this);
  }
}
