import { Box, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useState } from "react";
import { useStyles } from "./DateRangePicker.styles";
import { getFormValidation } from "../../FormValidations";
import { DATE_FORMAT } from "src/Shared/Enums";

type Props = {
  minDate?: any;
  maxDate?: any;
  dateFormat?: string;
  label?: string;
  disabledStart?: boolean;
  disabledEnd?: boolean;
  onChange?: (dataRange: DateRange) => void;
  readonly?: boolean;
  value?: DateRange;
};
type DateRange = {
  startDate?: any;
  endDate?: any;
};

type MinMaxDateRanges = {
  minStartDate?: any;
  maxStartDate?: any;
  maxEndDate?: any;
  minEndDate?: any;
};

const DateRangePicker: React.FC<Props> = ({
  minDate,
  maxDate,
  dateFormat,
  label,
  disabledStart,
  disabledEnd,
  onChange,
  readonly,
  value,
}) => {
  const [mixMaxDates, setMinMaxDates] = useState<MinMaxDateRanges>({
    minStartDate: minDate,
    maxEndDate: maxDate,
  });
  const classes = useStyles();

  const [dateRangeValue, setDateRangeValue] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const onStartDateChange = (date) => {
    const newMinMaxDates = { ...mixMaxDates };
    newMinMaxDates.minEndDate = date;
    setMinMaxDates(newMinMaxDates);
    const newDateRange = { ...dateRangeValue };
    newDateRange.startDate = date;
    setDateRangeValue(newDateRange);
    if (onChange) onChange(newDateRange);
  };

  const onEndDateChange = (date) => {
    const newDateRange = { ...dateRangeValue };
    newDateRange.endDate = date;
    setDateRangeValue(newDateRange);
    if (onChange) onChange(newDateRange);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <Box className={classes.root}>
        <DatePicker
          label={`${label} Start Date`}
          format={dateFormat}
          value={value?.startDate || null}
          minDate={mixMaxDates.minStartDate}
          maxDate={mixMaxDates.maxStartDate}
          defaultValue={null}
          disabled={disabledStart}
          onChange={(date) => {
            onStartDateChange(date);
          }}
          readOnly={readonly}
        />
        <Box>
          <Typography className={classes.between} variant="body1">
            -
          </Typography>
        </Box>
        <DatePicker
          label={`${label} End Date`}
          format={dateFormat}
          value={value?.endDate || null}
          minDate={mixMaxDates.minEndDate}
          maxDate={mixMaxDates.maxEndDate}
          defaultValue={null}
          disabled={disabledEnd}
          onChange={(date) => {
            onEndDateChange(date);
          }}
          readOnly={readonly}
        />
      </Box>
    </LocalizationProvider>
  );
};

DateRangePicker.defaultProps = {
  label: "",
  dateFormat: DATE_FORMAT.DATE_FORMAT,
};
export default DateRangePicker;
