import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { finalize, lastValueFrom } from "rxjs";
import { AccountModule } from "src/Modules";
import { ROUTE_URLS } from "src/Shared/Enums/RouteUrls.enum";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import Layout from "src/Shared/Layout/Layout";
import { PUBLIC_ROUTES } from "src/Shared/Tools/PUBLIC_ROUTES";

const Authorization: FC = () => {
  const {
    rootStore: { authStore, uiStore },
  } = useStore();

  const location = useLocation();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  const updateRedirectUrl = () => {
    setRedirectUrl(location.search.split("=")[1]);
  };

  const getRedirectURL = () => {
    if (PUBLIC_ROUTES.some((x) => x == location.pathname)) {
      return location.pathname;
    }
    return `${ROUTE_URLS.LOGIN}${location.search}`;
  };

  useEffect(() => {
    if (!authStore.hasToken) return navigate(getRedirectURL());
    uiStore.setLoader(true);
    authStore
      .validateToken()
      .pipe(
        finalize(() => {
          uiStore.setLoader(false);
        })
      )
      .subscribe({
        next: (user) => {
          authStore.setUserDetail(user);
          if (location.pathname === ROUTE_URLS.LOGIN) navigate(ROUTE_URLS.HOME);
          else if (location.search != "") {
            navigate(`/${location.search.split("=")[1]}`);
          } else {
            navigate(location.pathname);
          }
          return;
        },
        error: (error: AxiosError) => {
          navigate(`${ROUTE_URLS.LOGIN}${location.search}`);
        },
      });
  }, []);
  return (
    // <Layout>
      <Outlet />
    // </Layout>
  );
};

export default observer(Authorization);
