import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CoreModule from "./Modules/Core/Core.module";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "./Shared/Hooks";
import { ModalProvider } from "./Shared/Tools";
import { TddaTheme } from "./Shared/Themes/Tdda.theme";
import GlobalLoader from "./Shared/Components/GlobalLoader/GlobalLoader";
import Authorization from "./Shared/Components/Authorization/Authorization";
import { ROUTE_URLS } from "./Shared/Enums";
import { Login } from "./Modules/Account/Components";
import { LoginOutlined } from "@mui/icons-material";
import { observer } from "mobx-react";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  const {
    rootStore: { authStore, uiStore },
  } = useStore();
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={TddaTheme}>
          <ModalProvider>
            {uiStore.loading && <GlobalLoader />}
            <div>
              <Routes>
                <Route element={<Authorization />}>
                  <Route
                    path="/"
                    element={
                      authStore.hasToken ? (
                        <Navigate to={ROUTE_URLS.HOME} />
                      ) : (
                        <Navigate to={ROUTE_URLS.LOGIN} />
                      )
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot" element={<LoginOutlined />} />
                  <Route path="/*" element={<CoreModule />} />
                </Route>
              </Routes>
            </div>
          </ModalProvider>
        </ThemeProvider>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
};

export default observer(App);
