export const fields = {
  companySiteID: {
    label: "companySiteID",
    type: "text",
  },
  officeName: {
    label: "Office Name",
    type: "text",
    rules: "required|string",
  },
  division: {
    label: "Division",
    type: "text",
    rules: "string",
  },
  streetAddress: {
    label: "Street Address",
    type: "text",
    rules: "required|string",
  },
  suburb: {
    label: "Suburb",
    type: "text",
    rules: "string",
  },
  city: {
    label: "City",
    type: "text",
    rules: "required|string",
  },
  state: {
    label: "State",
    type: "text",
    rules: "string",
  },
  postCode: {
    label: "Post Code",
    type: "text",
    rules: "required",
  },
  totalNumberOfEmployees: {
    label: "Total Number Of Employees",
    type: "number",
    rules: "required",
  },
  isPrimaryOffice: {
    label: "Is Primary Office",
    value: false,
  },
};
