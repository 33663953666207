import { observer } from "mobx-react";
import { Field } from "mobx-react-form";

export const fields = [
  "sites",
  "sites[].companyRowID",
  "sites[].officeName",
  "sites[].division",
  "sites[].streetAddress",
  "sites[].suburb",
  "sites[].city",
  "sites[].state",
  "sites[].postCode",
  "sites[].totalNumberOfEmployees",
  "sites[].isPrimaryOffice",
];

export const labels = {
  sites: "Sites",
  "sites[].officeName": "Office Name",
  "sites[].division": "Division",
  "sites[].streetAddress": "Address",
  "sites[].suburb": "Suburb",
  "sites[].city": "City",
  "sites[].state": "State",
  "sites[].postCode": "Post Code",
  "sites[].totalNumberOfEmployees": "Total Employee Numbers",
  "sites[].isPrimaryOffice": "Primary Site",
};

export const values = {
  sites: [
    {
      companyRowID: "",
      officeName: "",
      division: "",
      streetAddress: "",
      suburb: "",
      city: "",
      state: "",
      postCode: "",
      totalNumberOfEmployees: "",
      isPrimaryOffice: true,
    },
  ],
};

export const rules = {
  "sites[].officeName": "string|required",
  "sites[].division": "string",
  "sites[].streetAddress": "string|required",
  "sites[].suburb": "string",
  "sites[].city": "string|required",
  "sites[].state": "string",
  "sites[].postCode": "string|required",
  "sites[].totalNumberOfEmployees": "numeric|required|min:1",
  "sites[].isPrimaryOffice": "required",
};

export const hooks = {
  sites: {
    onAdd(instance: Field) {
      instance.state.form.$("sites").map((v, i) => {
        v.$("isPrimaryOffice").observe({
          key: "value",
          call: ({ form, field, change }) => {
            if (field.value) {
              form.$("sites").map((site, ind) => {
                if (ind !== i) site.$("isPrimaryOffice").set("disabled", true);
              });
            } else {
              form.$("sites").map((site) => {
                site.$("isPrimaryOffice").set("disabled", false);
              });
            }
          },
        });
      });
      if (instance.state.form.values().sites.some((x) => x.isPrimaryOffice)) {
        instance
          .$(`${instance.size - 1}`)
          .$("isPrimaryOffice")
          .set("disabled", true);
      }
    },
  },
};

function getIndex(instance: Field) {
  return Number(instance.path.split(".")[1]);
}

export const types = {
  "sites[].totalNumberOfEmployees": "number",
};
