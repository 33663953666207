import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";
import { Observable } from "rxjs";
import AuthService from "../Services/Auth.service";
import baseApiPath from "./baseApiPath";

function addBody<T>(
  options: {
    observe?: HttpObserve;
    params?: any;
    responseType?: ResponseType;
    headers?: any;
  },
  body: T | null
): any {
  return {
    body,
    observe: options.observe,
    params: options.params,
    responseType: options.responseType,
    headers: options.headers,
  };
}

export type HttpObserve = "body" | "response";

type Body = object | string | number;

/**
 * Basically two options of return type:
 * const httpClient = new HttpClient(params);
 * httpClient.post<Type>(url, data, { observe: 'response' }) => return Observable<AxiosResponse<Type>>
 * where data wrapped in response.data object,
 *
 * Basic usage still the same:
 * const httpClient = new HttpClient(params);
 * httpClient.post<Type>(url, data) => return Observable<Type>
 *
 * We can add more response Interfaces based on response Type later.
 */
/* istanbul ignore next */
export default class HttpClient {
  private httpClient: AxiosInstance;
  constructor(params: AxiosRequestConfig = { baseURL: baseApiPath.mainApi }) {
    this.httpClient = axios.create({
      ...params,
    });

    this.httpClient.interceptors.request.use(
      (config) => {
        if (AuthService.accessToken && !config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${AuthService.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.httpClient.interceptors.response.use(
      this.successHandler.bind(this),
      this.errorHandler.bind(this)
    );
  }

  private getResponseObject<T>(
    response: AxiosResponse,
    observeType?: HttpObserve
  ): T;

  private getResponseObject<T>(
    response: AxiosResponse,
    observeType: "response"
  ): AxiosResponse<T>;

  private getResponseObject<T>(
    response: AxiosResponse,
    observeType: HttpObserve
  ): any {
    switch (observeType) {
      case "response":
        return { ...response };
      default:
        return response.data;
    }
  }

  private makeRequest<T>(
    method: string,
    url: string,
    options: {
      body?: any;
      observe: "response";
      params?: any;
      responseType?: ResponseType;
      headers?: any;
    }
  ): Observable<AxiosResponse<T>>;

  private makeRequest<T>(
    method: string,
    url: string,
    options?: {
      body?: any;
      observe?: "body";
      params?: any;
      responseType?: ResponseType;
      headers?: any;
    }
  ): Observable<T>;

  private makeRequest(
    method: string,
    url: string,
    options: {
      body?: any;
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
      headers?: any;
    } = {}
  ): Observable<any> {
    let request: AxiosPromise;
    const { body, observe, params, responseType, headers } = options;

    switch (method) {
      case "GET":
        request = this.httpClient.get<any>(url, { params, responseType });
        break;
      case "POST":
        request = this.httpClient.post<any>(url, body, {
          params,
          responseType,
          headers,
        });
        break;
      case "PUT":
        request = this.httpClient.put<any>(url, body, {
          params,
          responseType,
          headers,
        });
        break;
      case "PATCH":
        request = this.httpClient.patch<any>(url, body, {
          params,
          responseType,
          headers,
        });
        break;
      case "DELETE":
        request = this.httpClient.delete<any>(url, {
          params,
          responseType,
          data: body,
          headers,
        });
        break;

      default:
        throw new Error("Method not supported");
    }
    return new Observable<any>((subscriber) => {
      request
        .then((response: AxiosResponse) => {
          const data = this.getResponseObject(response, observe);

          subscriber.next(data);
          subscriber.complete();
        })
        .catch((err: AxiosError) => {
          subscriber.error(err);
          subscriber.complete();
        });
    });
  }

  public get<T>(
    url: string,
    options: {
      observe: "response";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<AxiosResponse<T>>;

  public get<T>(
    url: string,
    options?: {
      observe: "body";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<T>;

  public get(
    url: string,
    options: {
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
    } = {}
  ): Observable<any> {
    return this.makeRequest("GET", url, options as any);
  }

  public post<T>(
    url: string,
    body: Body,
    queryParams: {
      observe: "response";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<AxiosResponse<T>>;

  public post<T>(
    url: string,
    body: Body,
    options?: {
      observe?: "body";
      params?: any;
      responseType?: ResponseType;
      headers?: any;
    }
  ): Observable<T>;

  public post(
    url: string,
    body: Body,
    options: {
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
      headers?: any;
    } = {}
  ): Observable<any> {
    return this.makeRequest("POST", url, addBody(options, body));
  }

  public put<T>(
    url: string,
    body: Body,
    queryParams: {
      observe: "response";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<AxiosResponse<T>>;

  public put<T>(
    url: string,
    body: Body,
    options?: {
      observe?: "body";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<T>;

  public put(
    url: string,
    body: Body,
    options: {
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
    } = {}
  ): Observable<any> {
    return this.makeRequest("PUT", url, addBody(options, body));
  }

  public patch<T>(
    url: string,
    body: Body,
    queryParams: {
      observe: "response";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<AxiosResponse<T>>;

  public patch<T>(
    url: string,
    body: Body,
    options?: {
      observe?: "body";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<T>;

  public patch(
    url: string,
    body: Body,
    options: {
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
    } = {}
  ): Observable<any> {
    return this.makeRequest("PATCH", url, addBody(options, body));
  }

  public delete<T>(
    url: string,
    body: Body,
    options: {
      observe: "response";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<AxiosResponse<T>>;

  public delete<T>(
    url: string,
    body?: Body,
    options?: {
      observe: "body";
      params?: any;
      responseType?: ResponseType;
    }
  ): Observable<T>;

  public delete(
    url: string,
    body: Body,
    options: {
      observe?: HttpObserve;
      params?: any;
      responseType?: ResponseType;
    } = {}
  ): Observable<any> {
    return this.makeRequest("DELETE", url, addBody(options, body));
  }

  private successHandler(response: any) {
    return response;
  }

  private errorHandler(error: AxiosError<any>): any {
    if (!error.isAxiosError) {
      return error;
    }
    const { status, data }: any = error.response;

    if (status === 401) {
      AuthService.logout();
      return Promise.reject(error);
    }

    // since we are in Error Handler. We will catch only error responses
    // and in error responses we have only Client errors (400–499)
    // and Server errors (500–599)
    error.message =
      status === 400
        ? data?.title
        : status >= 404 && status <= 500
        ? data?.message || data?.messages?.join(",")
        : "Error Occurred.";
    return Promise.reject(error);
  }
}
