import { action, makeObservable, observable } from "mobx";
import { ISalesAction } from "src/Modules/Company/Interfaces";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { IUserActionsFilter } from "../Interfaces/IUserActionsFilter";
import { Subscription, finalize } from "rxjs";
import { AxiosError } from "axios";
import MyActionsService from "../Services/MyActions.service";
import DateTimeUtils from "src/Shared/Tools/DateTimeUtils";
import { ToastService } from "src/Shared/Services";

export class MyActionsStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;
  public assignedByGridLoading: boolean = false;
  public myTasksList: IPagedResult<ISalesAction> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  public tasksListAssignedByUser: IPagedResult<ISalesAction> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };
  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      assignedByGridLoading: observable,
      setGridLoading: action,
      setAssignedByGridLoading: action,
      getMyTasksGridList: action.bound,
      refreshMyTaskGridList: action,
      myTasksList: observable,
      tasksListAssignedByUser: observable,
      setTasksListAssignedByUser: action,
      getTaskListAssignedByUser: action,
    });
    this.rootStore = rootStore;
  }

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  setAssignedByGridLoading = (flag: boolean): void => {
    this.assignedByGridLoading = flag;
  };

  getMyTasksGridList = (
    request: IPageRequest<IUserActionsFilter>
  ): Subscription => {
    this.setGridLoading(true);
    return MyActionsService.getMyTasksList(request)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setMyTasksList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  getTaskListAssignedByUser = (
    request: IPageRequest<IUserActionsFilter>
  ): Subscription => {
    this.setAssignedByGridLoading(true);
    return MyActionsService.getTasksListAssignedByUser(request)
      .pipe(
        finalize(() => {
          this.setAssignedByGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setTasksListAssignedByUser(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  refreshMyTaskGridList = () => {
    this.getMyTasksGridList({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        userEmail: null,
      },
      sortCollection: [],
    });
  };

  refreshTaskAssignedByUserGridList = () => {
    this.getTaskListAssignedByUser({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        userEmail: null,
      },
      sortCollection: [],
    });
  };

  setMyTasksList(data: IPagedResult<ISalesAction>) {
    if (data) {
      data.results = data.results.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.taskDueDate = DateTimeUtils.dateToLocalDateZone(x.taskDueDate);
        return x;
      });
      this.myTasksList = data;
    }
  }

  setTasksListAssignedByUser(data: IPagedResult<ISalesAction>) {
    if (data) {
      data.results = data.results.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.taskDueDate = DateTimeUtils.dateToLocalDateZone(x.taskDueDate);
        return x;
      });
      this.tasksListAssignedByUser = data;
    }
  }
}
