import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { IconButton } from "@mui/material";

export const officeColumns = (updateOffice): GridColDef[] => {
  return[
  {
    field: "officeName",
    headerName: "Office Name",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "division",
    headerName: "Division",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "streetAddress",
    headerName: "Address",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "postCode",
    headerName: "Post Code",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "city",
    headerName: "City",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "suburb",
    headerName: "Suburb",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "totalNumberOfEmployees",
    headerName: "Total Numbers",
    editable: false,
    width: 150,
    sortable: false,
  },
  {
    field: "isPrimaryOffice",
    headerName: "Office Type",
    editable: false,
    width: 200,
    sortable: false,
    valueFormatter(params) {
      return params.value ? "Primary" : "Secondary";
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    editable: false,
    width: 70,
    sortable: false,
    // renderCell: (params) => (
    //   <IconButton color="primary">
    //     <EditTwoToneIcon />
    //   </IconButton>
    // ),
    renderCell: (params) => (
      <IconButton color="primary" onClick={() => updateOffice(params.row)}>
        <EditTwoToneIcon />
      </IconButton>
    ),
  },
]
};
