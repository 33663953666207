import {
  Autocomplete,
  Box,
  CircularProgress,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Observable, finalize } from "rxjs";
import { ISelectOption } from "src/Shared/Interfaces";
import { useStyles } from "./AsyncAutocomplete.styles";
import useDebounce from "src/Shared/Hooks/UseDebounce.hook";
import { CommonService } from "src/Shared/Services";

type Props = {
  label?: string;
  apiObservable?(search?: string): Observable<ISelectOption[]>;
  onValueChange?: (value: any) => void;
  sx?: SxProps<Theme>;
  value?: any;
  readOnly?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  error?: boolean;
};
const AsyncAutocomplete: React.FC<Props> = (
  {
    label,
    apiObservable,
    onValueChange,
    sx,
    value,
    readOnly,
    multiple,
    disabled,
    error,
  },
  props
) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = React.useState<ISelectOption[]>([]);
  const [inputValue, setinputValue] = React.useState<string>("");

  const onInputChange = (val: string) => {
    setinputValue(val);
  };

  useDebounce(
    () => {
      getOptions();
    },
    500,
    [inputValue]
  );

  const onOpen = () => {
    if (options.length === 0) {
      getOptions();
    }
  };

  function getOptions() {
    setLoading(true);
    if (apiObservable(inputValue)) {
      apiObservable(inputValue)
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe((options) => {
          setOptions(options);
        });
    }
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        multiple={multiple}
        sx={sx}
        value={value}
        fullWidth
        onOpen={onOpen}
        options={options}
        getOptionLabel={(option) => option?.label || ""}
        loading={loading}
        onInputChange={(e, value) => onInputChange(value)}
        getOptionKey={(options) => options.value}
        onChange={(_, value) => {
          if (onValueChange) {
            onValueChange(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={inputValue || ""}
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props}>
              <Box className={classes.optionBox}>
                <Box> {option.label}</Box>
                <Box> {option.value}</Box>
              </Box>
            </Box>
          );
        }}
        isOptionEqualToValue={(option, value) => option?.value === value.value}
        readOnly={readOnly}
      ></Autocomplete>
    </>
  );
};

AsyncAutocomplete.defaultProps = {
  apiObservable(search) {
    return CommonService.getUsers(search, 20);
  },
  value: "",
};
export default AsyncAutocomplete;
