import React, { useEffect, useState } from "react";
import { CustomDataGrid, FullScreenModal } from "src/Shared/Components";
import {
  ISalesDeal,
  ISalesDealHistory,
  ISalesDealHistoryFilter,
} from "../../Interfaces";
import { salesHistoryColumns } from "./SaleDealHistory.column";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IPageRequest } from "src/Shared/Interfaces";
import { observe } from "mobx";
import { observer } from "mobx-react";
import { useStore } from "src/Shared/Hooks";

type Props = {
  saleDeal: ISalesDeal;
};
const SaleDealHistory: React.FC<Props> = ({ saleDeal }) => {
  const {
    rootStore: {
      salesDealStore: {
        historyGridLoading,
        salesDealHistoryList,
        getSalesHistory,
      },
    },
  } = useStore();

  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [request, setRequest] = useState<IPageRequest<ISalesDealHistoryFilter>>(
    {
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        salesDealID: saleDeal.salesDealID,
      },
      sortCollection: [],
    }
  );

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: {
        salesDealID: saleDeal.salesDealID,
      },
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  useEffect(() => {
    getSalesHistory(request);
  }, [request]);

  return (
    <>
      <FullScreenModal title="Sale Deal History">
        <CustomDataGrid
          loading={historyGridLoading}
          columns={salesHistoryColumns()}
          rowSelection={false}
          rows={salesDealHistoryList.results || []}
          rowCount={salesDealHistoryList.totalNumberOfRecords || 0}
          style={{
            height: "calc(100vh - 110px)",
          }}
          getRowHeight={() => 'auto'}
          paginationModel={gridPagination}
          getRowId={(row: ISalesDealHistory) => row.salesDealHistoryID}
          onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
          rowHeight={80}
        />
      </FullScreenModal>
    </>
  );
};

export default observer(SaleDealHistory);
