import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { useModal, useStore } from "src/Shared/Hooks";
import { getFormValidation } from "src/Shared/Components";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { IResult } from "src/Shared/Interfaces";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import { finalize } from "rxjs";
import CompanyContactService from "../../Services/CompanyContact.service";
import { ICompanyContact } from "../../Interfaces/ICompanyContact";
import { fields } from "./fields";

type Props = {
  mode: VIEW_MODE;
  companyContact?: ICompanyContact;
  companyRowID?: string;
  primayContactId?: number;
};

const AddOrEditContact: FC<Props> = ({
  mode,
  companyContact,
  companyRowID,
  primayContactId
}) => {
  const {
    rootStore: {
      companyContactStore: { refreshCompanyContactList },
      uiStore,
    },
  } = useStore();

  const modal = useModal();
  const [buttonText, setbuttonText] = useState<string>("Add");
  const [tittle, settittle] = useState<string>("Add New Contact");
  const contactForm = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => contactForm.$(key);

  useEffect(() => {
    switch (mode) {
      case VIEW_MODE.ADD:
        {
        }
        break;
      case VIEW_MODE.EDIT:
        {
          setbuttonText("Update");
          settittle("Edit Contact");
        }
        break;
    }
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const reload = () => {
    modal.close();
    refreshCompanyContactList(companyRowID);
  };

  useEffect(() => {
    if (companyContact && mode == VIEW_MODE.EDIT) {
      contactForm.set(companyContact);
    }
  }, [companyContact]);

  const gridPropsForContacts: IGridFormControlProps = {
    row: 1,
    col: 6,
    itemResponsive: {
      lg: 12,
      sm: 12,
      md: 4,
    },
    inputControls: [
      {
        field: getField("fullName"),
        fieldKey: "fullName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("emailAddress"),
        fieldKey: "emailAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("jobTitle"),
        fieldKey: "jobTitle",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("phoneNumber"),
        fieldKey: "phoneNumber",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("staffPrimaryOfficeAddress"),
        fieldKey: "staffPrimaryOfficeAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("isPrimaryContact"),
        fieldKey: "isPrimaryContact",
        type: EDITOR_TYPES.SWITCH,
        hidden:
          mode == VIEW_MODE.ADD
            ? Boolean(primayContactId)
            : primayContactId && primayContactId !== companyContact.companyContactID,
      },
    ],
    form: contactForm,
  };

  const onFromSubmit = () => {
    if (!contactForm.isValid) {
      contactForm.showErrors();
      return;
    }
    const request: ICompanyContact = { ...contactForm.values() };
    setLoading(true);
    switch (mode) {
      case VIEW_MODE.ADD:
        request.companyRowID = companyRowID;
        uiStore.setGlobalLoader(true);
        var companyContact: ICompanyContact[] = [request];
        CompanyContactService.addCompanyContactList(companyContact)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
      case VIEW_MODE.EDIT:
        uiStore.setGlobalLoader(true);
        CompanyContactService.updateompanyContact(request)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
    }
  };

  return (
    <ModalContent
      onSaveButtonClick={onFromSubmit}
      buttonText={buttonText}
      title={tittle}
      btnLoading={loading}
    >
      <GridFormControl {...gridPropsForContacts} />
    </ModalContent>
  );
};

export default observer(AddOrEditContact);
