import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { Box, IconButton } from "@mui/material";
import { ActionStatus } from "../../Enums";
import { ISalesAction } from "../../Interfaces";
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export const assignedColumns = (editSalesAction, addNotes): GridColDef[] => {
  return [
    {
      field: "actionDescription",
      headerName: "Action Detail",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "actionTypeName",
      headerName: "Action Type",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "actionTaskTypeName",
      headerName: "Action Category",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "assignedToName",
      headerName: "Assigned To",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "franchiseName",
      headerName: "Supporting Region",
      editable: false,
      width: 180,
      sortable: false,
    },
    {
      field: "taskDueDateFormated",
      headerName: "Due Date",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "actionStatus",
      headerName: "Status",
      editable: false,
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              (params.row.actionStatusID === ActionStatus.InProgress &&
                "orange") ||
              (params.row.actionStatusID === ActionStatus.Completed &&
                "green") ||
              (params.row.actionStatusID === ActionStatus.Overdue && "red"),
            padding: params.value && "0px 10px 0px 10px",
            color: params.value && "white",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      editable: false,
      width: 200,
      sortable: false,
      renderCell: (params): any => {
        let row = params.row as ISalesAction;
        return (
          <Box>
            <Box>
              {row.lastModifiedBy}
            </Box>
            <Box>
            {row.updatedOn}
          </Box>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => editSalesAction(params.row)}>
          <EditTwoToneIcon />
        </IconButton>
      ),
    },
    {
      field: 'notes',
      headerName: "Notes",
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => addNotes(params.row)}><NoteAddIcon /></IconButton>
      ),
    }
  ];
};
