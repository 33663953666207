import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "./Root.store";

export class UIStore {
  public loading: boolean = false;
  public globalLoading: boolean = false;
  constructor() {
    makeObservable(this, {
      loading: observable,
      globalLoading:observable,
      setGlobalLoader:action,
      setLoader: action,
    });
  }

  setLoader(flag: boolean) {
    this.loading = flag;
  }
  setGlobalLoader = (flag: boolean) => {
    this.globalLoading = flag;
  };
}
