import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    boxSizing: "border-box",
    height: "calc(100vh - 105px)",
    overflowY: "auto",
  },
}));
