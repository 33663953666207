import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CompanyModule from "../Company/Company.module";
import Layout from "src/Shared/Layout/Layout";
import MyActionModule from "../MyActions/MyActions.module";
import { useStore } from "src/Shared/Hooks";

const CoreModule: FC = () => {
  const {
    rootStore: { commonStore },
  } = useStore();
  useEffect(() => {
    commonStore.loadCommonData();
  }, []);
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<Navigate to="company" />} />
        <Route path="company/*" element={<CompanyModule />} />
        <Route path="assigned-actions/*" element={<MyActionModule />} />
      </Routes>
    </Layout>
  );
};

export default observer(CoreModule);
