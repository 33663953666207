import { GridPaginationModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddOrEditAction from "src/Modules/Company/Components/AddOrEditAction/AddOrEditAction";
import AddOrEditNotes from "src/Modules/Company/Components/AddOrEditNotes/AddOrEditNotes";
import { IRouteParams, ISalesAction } from "src/Modules/Company/Interfaces";
import { CustomDataGrid } from "src/Shared/Components";
import { PicUp_Component } from "src/Shared/Enums/PicUpComponent";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { useStore, useModal } from "src/Shared/Hooks";
import { IPageRequest } from "src/Shared/Interfaces";
import { IUserActionsFilter } from "../../Interfaces";
import { taskViewColumns } from "./ActionAssignedByUserGrid.coloumn";
import { observer } from "mobx-react";

const ActionAssignedByUserGrid: React.FC = () => {
  const {
    rootStore: {
      myTasksStore: {
        tasksListAssignedByUser,
        assignedByGridLoading,
        getTaskListAssignedByUser,
      },
    },
  } = useStore();

  const modal = useModal();

  const [gridPagination, setGridPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [request, setRequest] = useState<IPageRequest<IUserActionsFilter>>({
    pageNumber: 1,
    pageSize: 10,
    filterCollection: {
      userEmail: "",
    },
    sortCollection: [],
  });

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setGridPagination(data);
    setRequest({
      filterCollection: {
        userEmail: "",
      },
      sortCollection: [],
      pageNumber: data.page + 1,
      pageSize: data.pageSize,
    });
  };

  const editSalesAction = (saleAction: ISalesAction) => {
    modal.open(
      <AddOrEditAction
        mode={VIEW_MODE.EDIT}
        saleAction={saleAction}
        picupComponent={PicUp_Component.ActionAssignedByUser}
      />
    );
  };

  useEffect(() => {
    getTaskListAssignedByUser(request);
  }, [request]);

  const addNotes = (saleAction: ISalesAction) => {
    modal.open(<AddOrEditNotes saleAction={saleAction}></AddOrEditNotes>);
  };

  return (
    <>
      <CustomDataGrid
        loading={assignedByGridLoading}
        rows={tasksListAssignedByUser.results || []}
        columns={taskViewColumns(editSalesAction, addNotes)}
        rowCount={tasksListAssignedByUser.totalNumberOfRecords || 0}
        getRowId={(row: ISalesAction) => row.companyActionID}
        style={{
          height: "calc(100vh - 140px)",
        }}
        paginationModel={gridPagination}
        onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
        onRowDoubleClick={(params) => editSalesAction(params.row)}
      />
    </>
  );
};

export default observer(ActionAssignedByUserGrid);
