import { FormatAlignJustify } from "@mui/icons-material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F2F3F4",
    height: "calc(100vh - 100px)",
    overflowY: "auto",
  },
}));
