import { Field } from "mobx-react-form";

export const fields = {
  companyRowID: {
    label: "companyRowID",
    type: "text",
  },
  companyName: {
    label: "Company Name",
    type: "text",
    rules: "required|string",
  },
  franchiseID: {
    label: "Supporting Region",
    rules: "required",
  },
  companyType: {
    label: "National/Regional",
    rules: "required",
  },
  vendorTypeIDs: {
    label: "TDDA/Healthtick",
    rules: "required",
    value: [],
  },
  industryID: {
    label: "Industry",
    rules: "required",
  },
  website: {
    label: "Website Url",
    rules: "string|url",
    placeholder: "https://",
  },
  totalStaffNumber: {
    label: "Total Employee Numbers",
    rules: "required|numeric",
    placeholder: "100",
    type: "number",
  },
  leadTypeID: {
    label: "Lead Type",
    rules: "required",
  },
  serviceTypeIDs: {
    label: "Services",
    rules: "required",
    value: [],
  },
  isSLASigned: {
    label: "Signed SLA",
    value: false,
    observers: [
      {
        key: "value",
        call: ({ form, field }) => {
          let SLAExpiredDate = form.$("slaExpiryDate");
          SLAExpiredDate.set("disabled", !field.value);
          if (!field.value) {
            SLAExpiredDate.clear();
          }
        },
      },
    ],
  },
  slaExpiryDate: {
    label: "SLA Expiry Date",
    disabled: true,
    rules: "required",
    value: null,
    defaultValue: null,
  },
  primaryCompanyRelationshipManager: {
    label: "TDDA Primary Relationship Manager",
    rules: "required",
    value: null,
  },
  additionalCompanyRelationshipManagers: {
    label: "TDDA Additional Relationship Manager(s)",
    value: [],
  },
  salesTotalValue: {
    label: "Client Deal Value",
  },
  dealStage: {
    label: "Deal Stage",
  },
  createdByOn: {
    label: "Created By/Created On",
  },
  modifiedByOn: {
    label: "Modified By/Modified On",
  },
  attachments: {
    label: "Attachment",
    value: [],
  },
};
