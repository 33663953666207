import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import MobxReactForm, { Form } from "mobx-react-form";
import { FormEvent } from "react";
import { reaction } from "mobx";

function getFormValidation(
  fields: any,
  options?: {
    successHandler?: (form: FormEvent) => void;
    errorHandler?: () => void;
    isNested?: boolean;
  }
): Form {
  const hooks: any = {};
  const updatedFields: any = options?.isNested ? fields : { fields };

  const plugins: any = {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator, form }) => {
        const messages = validator.getMessages("en");
        messages.required_if = "The :attribute is Required!";
        validator.setMessages("en", messages);
      },
    }),
  };

  const formOptions: any = { validateOnChange: true };

  if (options?.successHandler) {
    hooks.onSuccess = options?.successHandler;
  }

  if (options?.errorHandler) {
    hooks.onError = options?.errorHandler;
  }

  hooks.onInit = (form: MobxReactForm) => {
    reaction(
      () => form.values(),
      () => form.validate()
    );
  };

  return new MobxReactForm(updatedFields, {
    plugins,
    hooks,
    options: formOptions,
  });
}

export { getFormValidation };
