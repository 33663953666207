import { BaseService } from "./Base.service";
import { IBlobFile } from "../Interfaces/IBlobFile";
import { IAttachment } from "../Interfaces/IAttachment";

class BlobService extends BaseService {
  uploadFiles(files: FileList) {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      formData.append("files", element, element.name);
    }
    return this.http.post<IAttachment[]>("/api/blob/uploadfiles", formData);
  }
}
export default new BlobService();
