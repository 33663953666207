import { EffectCallback, useCallback, useEffect, useRef } from "react";

export default function useTimeout(effect: EffectCallback, delay: number) {
  const callbackRef = useRef<EffectCallback>(effect);
  const timeoutRef = useRef(null);

  useEffect(() => {
    callbackRef.current = effect;
  }, [effect]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}
