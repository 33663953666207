import { Box, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useStyles } from "../DateRangePicker/DateRangePicker.styles";
import { NumericFormatCustom } from "../NumericFormatCustom/NumericFormatCustom";
import { action } from "mobx";
import { observer } from "mobx-react";

type Props = {
  min?: number;
  max?: number;
  label?: string;
  disabled?: boolean;
  onChange?: (range: NumericRange) => void;
  readonly?: boolean;
  value?: NumericRange;
};

type NumericRange = {
  min?: number;
  max?: number;
};

const NumericRangeInput: React.FC<Props> = ({
  min,
  max,
  label,
  disabled,
  onChange,
  readonly,
  value,
}) => {
  const classes = useStyles();
  useEffect(() => {
    value = value
      ? value
      : {
          min: null,
          max: null,
        };
  }, []);

  const onMinValChange = (val) => {
    value.min = val ? Number(val) : null;
    onChange(value);
  };

  const onMaxValChange = (val) => {
    value.max = val ? Number(val) : null;
    onChange(value);
  };

  return (
    <Box className={classes.root}>
      <TextField
        label={`Min ${label} `}
        value={value?.min || ""}
        defaultValue={null}
        disabled={disabled}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
          onMinValChange(target.value);
        }}
        InputProps={{
          readOnly: readonly,
          inputComponent: NumericFormatCustom as any,
        }}
      />
      <Box>
        <Typography className={classes.between} variant="body1">
          -
        </Typography>
      </Box>
      <TextField
        label={`Max ${label} `}
        value={value?.max || ""}
        defaultValue={null}
        disabled={disabled}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
          onMaxValChange(target.value);
        }}
        InputProps={{
          readOnly: readonly,
          inputComponent: NumericFormatCustom as any,
        }}
        error={value.max && value.min > value.max}
      />
    </Box>
  );
};
NumericRangeInput.defaultProps = {
  value: { min: null, max: null },
};
export default observer(NumericRangeInput);
