import * as Validator from "validatorjs";
export const fields = {
  companyContactID: {},
  fullName: {
    label: "Full Name",
    rules: "required|string",
  },
  emailAddress: {
    label: "Email",
    rules: "required|email",
  },
  jobTitle: {
    label: "Job Title",
    rules: "required",
  },
  phoneNumber: {
    label: "Phone Number",
    rules: "required|numeric|digits_between:8,15",
  },
  isPrimaryContact: {
    label: "Primary Contact",
    value: false,
  },
  staffPrimaryOfficeAddress: {
    label: "Address",
  },
};

