import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SalesDeal from "../SalesDeal/SalesDeal";
import SalesAction from "../SalesAction/SalesAction";
import { useStyles } from "./CompanyOverview.styles";
import { useStore } from "src/Shared/Hooks";
import { useParams } from "react-router-dom";
import { IRouteParams } from "../../Interfaces";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import CompanyOverviewPanel from "../CompanyOverviewPanel/CompanyOverviewPanel";

const CompanyOverview: FC = () => {
  const [value, setValue] = useState(0);
  const {
    rootStore: {
      companyStore: { companyOverview, getCompanyOverview, overViewLoading },
    },
  } = useStore();
  const { companyRowID } = useParams<Partial<IRouteParams>>();

  useEffect(() => {

    getCompanyOverview(companyRowID);
  }, [true]);
  const classes = useStyles();

  const handleChange = (_, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4} className={classes.root}>
          <Box sx={{ m: 3 }}>
            <CompanyOverviewPanel companyOverview={companyOverview} />
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab
                  sx={{ width: "100%" }}
                  label="Sales Pipeline"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ width: "100%" }}
                  label="Assigned Actions"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <Box sx={{ p: 3 }}>
              {value == 0 && <SalesDeal />}
              {value == 1 && <SalesAction />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default observer(CompanyOverview);
