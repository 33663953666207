import {
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { ModalContext } from "../Hooks";
import { getTempId } from "./Utils";

type ModalListItem = {
  id: string;
  content: any;
};
type Props = {
  children: any;
};

export const ModalProvider: FC<Props> = ({ children }) => {
  const [modalList, setModalList] = useState<ModalListItem[]>([]);

  const actions = useMemo(
    () => ({
      open(node: ReactElement) {
        setModalList((modalList) => [...modalList, createModal(node)]);
      },
      close() {
        setModalList((modalList) => [
          ...modalList.splice(0, modalList.length - 1),
        ]);
      },
    }),
    []
  );

  const createModal = (content: ReactNode): ModalListItem => {
    return { id: getTempId(), content: content };
  };

  return (
    <>
      <ModalContext.Provider value={actions}>
        {modalList.map((model) => (
          <Fragment key={model.id}>{model.content}</Fragment>
        ))}
        {children}
      </ModalContext.Provider>
    </>
  );
};
