import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  flexRow: {
    marginBottom: theme.spacing(5),
  },
  flexBlock: {},
  radioLabel: {
    "&.MuiFormLabel-root": {
      width: "100%",
      transform: "none",
      maxWidth: "100%",
      pointerEvents: "all",
      fontFamily: "Poppins",
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  },
  radioBox: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: "5px",
    padding: "0 15px",
    "& .MuiFormControlLabel-root": {
      flexBasis: "48%",
    },
  },
  selectBox: {
    "&.MuiOutlinedInput-root": {
      minWidth: "185px",
      width: "100%",
    },
    // '& .MuiOutlinedInput-notchedOutline': {
    //   borderWidth: '2px',
    // },
  },
  selectLabel: {
    // '&.MuiInputLabel-root': {
    //   color: theme.palette.text.primary,
    //   fontWeight: 500,
    // },
  },
  input: {
    "& .Mui-disabled.MuiOutlinedInput-root": {
      background: "rgba(0,0,0,0.1)",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0 10px",
    },
  },
  switch: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
  },
  swichBox: {
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "start",
  },
  textArea:{
    width: "100%"
  }
}));
