import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100vh - 240px)",
    overflowY: "auto",
    paddingTop: theme.spacing(1),
  },
  submitButtonBox: {
    display: "flex",
    justifyContent: "end",
  },
}));
