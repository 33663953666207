import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useStyles } from "./CompanyGridToolbar.styles";
import React, { useState } from "react";
import { ICompanyFilter } from "../../Interfaces";
import { ISelectOption } from "src/Shared/Interfaces";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import { ToastService } from "src/Shared/Services";
import useDebounce from "src/Shared/Hooks/UseDebounce.hook";
import { ICompanySearchFilter } from "../../Interfaces/ICompanySearchFilter";
import CompanyService from "../../Services/Company.service";
import { finalize } from "rxjs";
import { AxiosError } from "axios";
import MoveUpTwoToneIcon from "@mui/icons-material/MoveUpTwoTone";
import { observer } from "mobx-react";
import { useModal, useStore } from "src/Shared/Hooks";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import CompanyGridFilterPanel from "../CompanyGridFilterPanel/CompanyGridFilterPanel";

type Props = {
  onApplyFilter?: () => void;
};

const CompanyGridToolbar: React.FC<Props> = ({ onApplyFilter }: Props) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState<ICompanySearchFilter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setinputValue] = useState<string>("");
  const modal = useModal();

  const {
    rootStore: {
      companyStore: { companyFilterForm, companyFilterCollection },
    },
  } = useStore();

  const getField = (key: string) => companyFilterForm.$(key);

  const classes = useStyles();

  const onValueChange = (option: ICompanySearchFilter) => {
    if (option?.isArchived) {
      navigate(`${option.value}/archived`);
    } else {
      getField("companyName").set("value", option || null);
      onApplyFilter();
    }
  };
  useDebounce(
    () => {
      if (inputValue.length >= 1) {
        loadOptions(inputValue);
      }
    },
    500,
    [inputValue]
  );

  const badgeContent = () => {
    const collectionCount =
      (
        companyFilterCollection &&
        Object.values(companyFilterCollection).filter(
          (x: any) =>
            x != null &&
            x != "" &&
            (x.length > 0 ||
              (x instanceof Object &&
                ((x.hasOwnProperty("startDate") && x?.startDate != null) ||
                  (x.hasOwnProperty("min") && x?.min != null) ||
                  (x.hasOwnProperty("value") && x?.value != ""))) ||
              (typeof x == "number" && x > 0))
        )
      )?.length || 0;
    return collectionCount;
  };

  const loadOptions = (companyName: string) => {
    if (companyName && companyName.length >= 1) {
      setLoading(true);
      CompanyService.getCompanySearch(companyName, 20)
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe({
          next: (data) => {
            setOptions(data);
          },
          error: (error: AxiosError) => ToastService.axiosError(error),
        });
    }
  };
  const onAdvanceFilterClick = () => {
    modal.open(
      <CompanyGridFilterPanel onApplyFilter={() => onApplyFilter()} />
    );
  };
  const onReset = () => {
    companyFilterForm.reset();
    onApplyFilter();
  };

  return (
    <GridToolbarContainer className={classes.container}>
      <Grid container>
        <Grid item lg={8} className={classes.filterBox}>
          <Autocomplete
            sx={{ width: 350 }}
            fullWidth
            value={getField("companyName").value || null}
            options={options}
            getOptionLabel={(option) => option.label}
            loading={loading}
            onInputChange={(e, value) => setinputValue(value)}
            getOptionKey={(options) =>
              `${options.isArchived ? "A" : "E"} - ${options.value}`
            }
            onChange={(_, value) => {
              onValueChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Client"
                value={inputValue}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  <Box className={classes.optionBox}>
                    <Box> {option.label}</Box>
                    <Box>
                      {option.isArchived ? (
                        <MoveUpTwoToneIcon color="primary" />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>
        <Grid item lg={4} className={classes.buttonBox}>
          {badgeContent() > 0 && (
            <Button
              variant="contained"
              startIcon={<FilterAltTwoToneIcon />}
              onClick={onReset}
              sx={{
                mr: 2,
              }}
            >
              Clear Filter
            </Button>
          )}

          <Badge
            color="secondary"
            badgeContent={badgeContent()}
            invisible={badgeContent() <= 0}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Button
              variant="contained"
              startIcon={<FilterAltTwoToneIcon />}
              onClick={onAdvanceFilterClick}
              sx={{
                mr: 2,
              }}
            >
              Advanced Filter
            </Button>
          </Badge>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            component={Link}
            to={`add`}
          >
            Add New Client
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};
export default observer(CompanyGridToolbar);
