import { action, makeObservable, observable } from "mobx";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { ICompany, ICompanyFilter, ICompanyList } from "../Interfaces";
import CompanyService from "../Services/Company.service";
import { Subscription, finalize } from "rxjs";
import { AxiosError } from "axios";
import { ICompanyOverview } from "../Interfaces/ICompanyOverview";
import { IArchivedCompany } from "../Interfaces/IArchivedCompany";
import { ToastService } from "src/Shared/Services";
import Form from "mobx-react-form";
import { getFormValidation } from "src/Shared/Components";
import DateTimeUtils from "../../../Shared/Tools/DateTimeUtils";
import { numericFormatter } from "react-number-format";

export class CompanyStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;

  public companyList: IPagedResult<ICompanyList> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  public company: ICompany | null = null;
  public archivedCompany: IArchivedCompany | null = null;
  public companyOverview: ICompanyOverview | null = null;
  public overViewLoading: boolean = false;
  public companyFilterForm: Form = null;
  public companyFilterCollection: ICompanyFilter = {
    companyName: null,
    modifiedBy: { label: "", value: "" },
    franchiseID: [],
    totalStaffNumber: null,
    vendorTypeID: null,
    leadTypeIDs: [],
    services: [],
    dealStage: [],
    isSLASigned: null,
    slaExpiryDate: null,
    primaryRelationshipManager: [],
    createdBy: [],
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      companyList: observable,
      company: observable,
      companyOverview: observable,
      setGridLoading: action,
      getCompanies: action.bound,
      setCompanyList: action,
      getCompany: action,
      resetCompanyList: action,
      getCompanyOverview: action,
      overViewLoading: observable,
      archivedCompany: observable,
      getArchivedCompany: action,
      intilizeFilterForm: action,
      companyFilterForm: observable,
      companyFilterCollection: observable,
      setCompanyFilterCollection: action,
    });
    this.rootStore = rootStore;
  }

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };
  getCompanies(request: IPageRequest<ICompanyFilter>): Subscription {
    this.setGridLoading(true);
    return CompanyService.getCompanies(request)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setCompanyList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  }

  setCompanyList(data: IPagedResult<ICompanyList>) {
    if (data) {
      data.results = data.results.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalDateZone(x.createdOn);
        x.updatedOn = DateTimeUtils.dateToLocalDateZone(x.updatedOn);
        return x;
      });
      this.companyList = data;
    }
  }

  setCompanyFilterCollection = (filterCollection: ICompanyFilter) => {
    this.companyFilterCollection = filterCollection;
  };

  resetCompanyList = () => {
    const companyList = { ...this.companyList };
    companyList.results = [];
    this.setCompanyList(companyList);
  };

  getCompany = (companyRowID: string) => {
    this.rootStore.uiStore.setLoader(true);
    return CompanyService.getCompany(companyRowID)
      .pipe(
        finalize(() => {
          this.rootStore.uiStore.setLoader(false);
        })
      )
      .subscribe({
        next: (data) => {
          if (data) {
            data.createdByOn = `${
              data.createdBy
            } - ${DateTimeUtils.dateToLocalTimeZone(data.createdOn)}`;
            data.modifiedByOn = `${
              data.lastModifiedBy
            } - ${DateTimeUtils.dateToLocalTimeZone(data.modifiedOn)}`;
            data.slaExpiryDate =
              data.slaExpiryDate != null
                ? DateTimeUtils.dateToLocalTimeZone(data.slaExpiryDate)
                : data.slaExpiryDate;
            data.salesTotalValue = numericFormatter(`${data.salesTotalValue}`, {
              thousandSeparator: true,
              decimalScale: 2,
              fixedDecimalScale: true,
              prefix: "$",
            });
          }
          this.company = data;
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  getArchivedCompany = (archivedCRMDataID: number) => {
    this.rootStore.uiStore.setLoader(true);
    return CompanyService.getArchivedCompany(archivedCRMDataID)
      .pipe(
        finalize(() => {
          this.rootStore.uiStore.setLoader(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.archivedCompany = data;
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  getCompanyOverview = (companyRowID: string) => {
    this.rootStore.uiStore.setGlobalLoader(true);
    this.overViewLoading = true;
    return CompanyService.getCompanyOverview(companyRowID)
      .pipe(
        finalize(() => {
          this.rootStore.uiStore.setGlobalLoader(false);
          this.overViewLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.companyOverview = data;
          this.companyOverview.slaExpiryDate =
            this.companyOverview.slaExpiryDate != null
              ? DateTimeUtils.dateToLocalDateZone(
                  this.companyOverview.slaExpiryDate
                )
              : this.companyOverview.slaExpiryDate;
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  intilizeFilterForm = (fields: {}) => {
    if (this.companyFilterForm === null)
      this.companyFilterForm = getFormValidation(fields);
  };
}
