import { Avatar, Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { numericFormatter } from "react-number-format";

export const columns: GridColDef[] = [
  {
    field: "companyName",
    headerName: "Company Name",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "salesTotalValue",
    headerName: "Sales Pipeline Value",
    editable: false,
    width: 150,
    sortable: false,
    valueFormatter(params) {
      return numericFormatter(`${params.value}`, {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: "$",
      });
    },
  },
  {
    field: "franchiseName",
    headerName: "Supporting Region",
    editable: false,
    width: 180,
    sortable: false,
  },
  {
    field: "tddaRelationshipManager",
    headerName: "TDDA Relationship Manager",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "inProgressActions",
    headerName: "In Progress Action",
    editable: false,
    width: 130,
    sortable: false,
    renderCell: (params) => (
      <Avatar
        sx={{
          backgroundColor: params.value > 0 && "orange",
        }}
      >
        {params.value}
      </Avatar>
    ),
  },
  {
    field: "overdueActions",
    headerName: "Overdue Action",
    editable: false,
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <Avatar
        sx={{
          backgroundColor: params.value > 0 && "red",
        }}
      >
        {params.value}
      </Avatar>
    ),
  },
  {
    field: "dealStage",
    headerName: "Deal Stage",
    editable: false,
    width: 230,
    sortable: false,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    editable: false,
    width: 150,
    sortable: false,
  },
  {
    field: "lastModifiedBy",
    headerName: "Modified By",
    editable: false,
    width: 180,
    sortable: false,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    editable: false,
    width: 100,
    sortable: false,
  },
  {
    field: "updatedOn",
    headerName: "Updated On",
    editable: false,
    width: 100,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Edit",
    editable: false,
    width: 60,
    sortable: false,
    renderCell: (params) => (
      <IconButton color="primary" component={Link} to={`${params.id}/edit`}>
        <EditTwoToneIcon />
      </IconButton>
    ),
  },
  {
    field: "edit",
    headerName: "Overview",
    editable: false,
    width: 80,
    sortable: false,
    renderCell: (params) => (
      <IconButton color="primary" component={Link} to={`${params.id}/overview`}>
        <LaunchTwoToneIcon />
      </IconButton>
    ),
  },
];
