import { useEffect, useMemo, useState } from "react";
import { getFormValidation } from "src/Shared/Components";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { fields } from "./fields";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { ICompanyOffice } from "../../Interfaces";
import { useModal, useStore } from "src/Shared/Hooks";
import CompanyOfficeService from "../../Services/CompanyOffice.service";
import { finalize } from "rxjs";
import { IResult } from "src/Shared/Interfaces";
import { ToastService } from "src/Shared/Services";
import { AxiosError } from "axios";

type Props = {
  mode: VIEW_MODE;
  companyOffice?: ICompanyOffice;
  companyRowID?: string;
  primayOfficeId?: number;
};

const AddOrEditOffice: React.FC<Props> = ({
  mode,
  companyOffice,
  companyRowID,
  primayOfficeId,
}) => {
  const {
    rootStore: {
      companyOfficeStore: { refreshCompanyOfficeList },
      uiStore, 
    },
  } = useStore();

  const modal = useModal();
  const [buttonText, setbuttonText] = useState<string>("Add");
  const [tittle, settittle] = useState<string>("Add Office");

  const officeForm = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => officeForm.$(key);

  useEffect(() => {
    // officeForm.reset();
    switch (mode) {
      case VIEW_MODE.ADD:
        {
        }
        break;
      case VIEW_MODE.EDIT:
        {
          setbuttonText("Update");
          settittle("Edit Office");
        }
        break;
    }
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const reload = () => {
    modal.close();
    refreshCompanyOfficeList(companyRowID);
  };

  useEffect(() => {
    if (companyOffice && mode == VIEW_MODE.EDIT) {
      officeForm.set(companyOffice);
    }
  }, [companyOffice]);

  const gridProps: IGridFormControlProps = {
    row: 2,
    col: 5,
    spacing: 1,
    itemResponsive: {
      lg: 6,
      md: 6,
      sm: 12,
    },
    inputControls: [
      {
        field: getField("officeName"),
        fieldKey: "officeName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("division"),
        fieldKey: "division",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("streetAddress"),
        fieldKey: "streetAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("suburb"),
        fieldKey: "suburb",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("city"),
        fieldKey: "city",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("state"),
        fieldKey: "state",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("postCode"),
        fieldKey: "postCode",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("totalNumberOfEmployees"),
        fieldKey: "totalNumberOfEmployees",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("isPrimaryOffice"),
        fieldKey: "isPrimaryOffice",
        type: EDITOR_TYPES.SWITCH,
        hidden:
          mode == VIEW_MODE.ADD
            ? Boolean(primayOfficeId)
            : primayOfficeId && primayOfficeId !== companyOffice.companySiteID,
      },
    ],
    form: officeForm,
  };

  const onSubmit = () => {
    if (!officeForm.isValid) {
      officeForm.showErrors();
      return;
    }
    const request: ICompanyOffice = { ...officeForm.values() };
    setLoading(true);
    switch (mode) {
      case VIEW_MODE.ADD:
        request.companyRowID = companyRowID;
        uiStore.setGlobalLoader(true);
        var companyOffice: ICompanyOffice[] = [request];
        CompanyOfficeService.addCompanySite(companyOffice)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
              // modal.close();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;

      case VIEW_MODE.EDIT:
        CompanyOfficeService.updateOffice(request)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
              // modal.close();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
    }
  };

  return (
    <>
      <ModalContent
        onSaveButtonClick={onSubmit}
        buttonText={buttonText}
        title={tittle}
        btnLoading={loading}
      >
        <GridFormControl {...gridProps} />
      </ModalContent>
    </>
  );
};

export default AddOrEditOffice;
