import { Observable, catchError, map, tap } from "rxjs";
import { IAuth, IUser } from "../Interfaces";
import HttpClient from "../Tools/HttpClient";
import baseApiPath from "../Tools/baseApiPath";
import { API_Urls } from "../Tools/API_Urls";
import { AxiosError } from "axios";

class AuthService {
  get accessToken() {
    return localStorage.getItem("AuthToken");
  }

  set setAccessToken(token: string) {
    localStorage.setItem("AuthToken", token);
  }
  public login(request: IAuth): Observable<IUser> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.mainApi,
    });
    return http
      .post<any>(API_Urls.loginApi, request, {
        headers: {
          "content-language": "en",
          utcOffSet: "-330",
        },
      })
      .pipe(
        // map((data) => data.data as IUser),
        tap((data: IUser) => {
          if (data && data.token) {
            this.setAccessToken = data.token;
          }
        })
      );
  }

  public isTokenValid(): Observable<IUser> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.mainApi,
    });
    return http.get<any>(API_Urls.isTokenValid).pipe(
      // map((data) => data.data as IUser),
      tap((data: IUser) => {
        if (data && data.token) {
          this.setAccessToken = data.token;
        }
      })
    );
  }

  public logout() {
    localStorage.removeItem("AuthToken");
  }
}
export default new AuthService();
