import { IPageRequest, IPagedResult, IResult } from "src/Shared/Interfaces";
import { ISalesDealFilter } from "../Interfaces/ISalesDealFilter";
import { Observable } from "rxjs";
import { ISalesAction } from "../Interfaces/ISalesAction";
import HttpClient from "src/Shared/Tools/HttpClient";
import { SalesActionApiUrls } from "../Tools/SalesActionApiUrls";
import { BaseService } from "src/Shared/Services/Base.service";
import { IActionNote } from "../Interfaces/IActionNote";
import { IActionStatusRequest } from "../Interfaces/IActionStatusRequest";

class SalesActionService extends BaseService {
  public getSalesActionList(
    request: IPageRequest<ISalesDealFilter>
  ): Observable<IPagedResult<ISalesAction>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ISalesAction>>(
      SalesActionApiUrls.GetSalesAction,
      request
    );
  }

  public addSalesAction(request: ISalesAction) {
    return this.http.post<IResult<string>>(
      SalesActionApiUrls.AddSalesAction,
      request
    );
  }

  public updateSalesAction(request: ISalesAction) {
    return this.http.post<IResult<string>>(
      SalesActionApiUrls.UpdateSalesAction,
      request
    );
  }
  public addActionNotes(request: IActionNote) {
    return this.http.post<IResult<string>>(
      SalesActionApiUrls.AddActionNotes,
      request
    );
  }

  public getActionNotes(companyActionID: number): Observable<IActionNote[]> {
    return this.http.get<IActionNote[]>(
      SalesActionApiUrls.GetActionNotes(companyActionID)
    );
  }
  public setActionStatusCompleted(request: IActionStatusRequest) {
    return this.http.post<IResult<string>>(
      SalesActionApiUrls.UpdateActionStatus,request)
  }
}

export default new SalesActionService();
