const baseSaleActionApiUrls = "/api/Action";

export const SalesActionApiUrls = {
  GetSalesAction: `${baseSaleActionApiUrls}/Get`,
  AddSalesAction: `${baseSaleActionApiUrls}`,
  UpdateSalesAction: `${baseSaleActionApiUrls}/Update`,
  MyTaskViewList: `${baseSaleActionApiUrls}/UserAssignedActions`,
  AddActionNotes: `${baseSaleActionApiUrls}/AddAction`,
  GetActionNotes: (companyActionID: number) =>
    `${baseSaleActionApiUrls}/GetActionNotes?companyActionID=${companyActionID}`,
  UpdateActionStatus: `${baseSaleActionApiUrls}/StatusUpdate`,
  AssignedActionsByUser: `${baseSaleActionApiUrls}/AssignedActionsByUser`,
};
