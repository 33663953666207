import { IActionTaskType } from "src/Modules/Company/Interfaces/IActionTaskType";
import { ISelectOption } from "../Interfaces";
import { COMMON_API_URLS } from "../Tools/CommonApiUrls";
import HttpClient from "../Tools/HttpClient";
import baseApiPath from "../Tools/baseApiPath";
import { BaseService } from "./Base.service";

class CommonService extends BaseService {
  getActionStatusList = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.ActionStatusList);
  getActionTaskTypeList = () =>
    this.http.get<IActionTaskType[]>(COMMON_API_URLS.ActionTaskTypeList);
  getActionTypeList = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.ActionTypeList);
  getDealStatusList = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.DealStatusList);
  getLeadTypeList = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.LeadTypeList);
  getServiceTypeList = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.ServiceTypeList);
  getSupportiveRegions = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.SupportiveRegions);
  getVendorTypes = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.VendorTypes);
  getIndustries = () =>
    this.http.get<ISelectOption[]>(COMMON_API_URLS.Industries);

  getUsers = (userName?: string, usersCount?: number) => {
    const http = new HttpClient({ baseURL: baseApiPath.loginApi });
    return http.get<ISelectOption[]>(
      COMMON_API_URLS.Users(userName, usersCount)
    );
  };
}

export default new CommonService();
