import React, { ReactNode } from "react";
import { Header } from "./MainLayout";
import { observer } from "mobx-react";

type Props = {
  children?: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default observer(Layout);
