import { FC, useEffect, useMemo, useState } from "react";
import { useStyles } from "./Login.styles";
import { fields } from "./fields";
import { inject, observer } from "mobx-react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  TextField,
} from "@mui/material";
import { IAuth } from "../../../../Shared/Interfaces";
import AuthService from "../../../../Shared/Services/Auth.service";
import { getFormValidation } from "../../../../Shared/Components/FormValidations/MobxFormValidation";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { ROUTE_URLS } from "src/Shared/Enums/RouteUrls.enum";
import { finalize } from "rxjs";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";

const Login: FC = () => {
  const {
    rootStore: { authStore, uiStore },
  } = useStore();

  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const classes = useStyles();
  const form = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => form.$(key);
  const onLogin = () => {
    uiStore.setLoader(true);
    const { email, password } = form.values();
    const reqData: IAuth = {
      username: email,
      password,
      deviceToken: "abc",
      deviceType: "Web",
    };

    AuthService.login(reqData)
      .pipe(
        finalize(() => {
          uiStore.setLoader(false);
        })
      )
      .subscribe({
        next: (data) => {
          authStore?.setUserDetail(data);
          if (location.search != "") {
            let redirect = location.search.split("=")[1];
            navigate(`/${redirect}`);
          } else {
            navigate(ROUTE_URLS.HOME);
          }
        },
        error: (err:AxiosError) => {
          ToastService.axiosError(err)
        },
      });
  };

  const submitForm = (): void => {
    form.submit({
      onSuccess: () => onLogin(),
    });
  };

  return (
    <>
      <div className="login_box">
        <div className="container">
          <div className="login_cont">
            <div>
              <TextField
                className={classes.inputField}
                margin="dense"
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                {...form.$("email").bind()}
                error={!!form.$("email").error}
              />
            </div>
            <div>
              <TextField
                className={classes.inputField}
                margin="dense"
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                {...form.$("password").bind()}
                error={!!form.$("password").error}
                type="password"
              />
            </div>

            <Box
              sx={{
                paddingTop: "10px",
              }}
            >
              <Button
                className={classes.inputField}
                variant="contained"
                onClick={submitForm}
                disabled={!form.isValid}
              >
                Login
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Login);
