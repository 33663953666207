import { action, makeObservable, observable } from "mobx";
import { IRootStore } from "src/Shared/Stores/Root.store";
import SalesDealService from "../Services/SalesDeal.service";
import { Subscription, finalize } from "rxjs";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { AxiosError } from "axios";
import { ISalesDealFilter } from "../Interfaces/ISalesDealFilter";
import { ISalesDeal } from "../Interfaces/ISalesDeal";
import { ToastService } from "src/Shared/Services";
import DateTimeUtils from "src/Shared/Tools/DateTimeUtils";
import { ISalesDealHistoryFilter } from "../Interfaces/ISalesDealHistoryFilter";
import { ISalesDealHistory } from "../Interfaces";

export class SalesDealStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;
  public historyGridLoading: boolean = false;

  public saleDealDialog: boolean = false;

  public salesDealList: IPagedResult<ISalesDeal> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  public salesDealHistoryList: IPagedResult<ISalesDealHistory> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };
  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      historyGridLoading: observable,
      setGridLoading: action,
      setHistoryGridLoading: action,
      getSalesGridList: action.bound,
      getSalesHistory: action.bound,
      salesDealList: observable,
      salesDealHistoryList: observable,
      setSalesGridList: action,
      saleDealDialog: observable,
      setsaleDealDialog: action,
      refreshSalesGridList: action,
      setSalesGridHistoryList: action,
    });
    this.rootStore = rootStore;
  }

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  setsaleDealDialog = (flag: boolean): void => {
    this.saleDealDialog = flag;
  };

  setHistoryGridLoading = (flag: boolean): void => {
    this.historyGridLoading = flag;
  };

  getSalesGridList(request: IPageRequest<ISalesDealFilter>): Subscription {
    this.setGridLoading(true);
    return SalesDealService.getSalesDealList(request)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setSalesGridList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  }

  refreshSalesGridList = (companyRowID: string) => {
    this.getSalesGridList({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
    });
  };

  setSalesGridList(data: IPagedResult<ISalesDeal>) {
    if (data) {
      data.results = data.results.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.updatedOn = DateTimeUtils.dateToLocalTimeZone(x.updatedOn);
        return x;
      });
      this.salesDealList = data;
    }
  }

  setSalesGridHistoryList(data: IPagedResult<ISalesDealHistory>) {
    if (data && data.results) {
      data.results = data.results.map((x) => {
        x.updatedOn = DateTimeUtils.dateToLocalTimeZone(x.updatedOn);
        return x;
      });
      this.salesDealHistoryList = data;
    }
  }

  getSalesHistory(
    request: IPageRequest<ISalesDealHistoryFilter>
  ): Subscription {
    this.setHistoryGridLoading(true);
    return SalesDealService.getSalesDealHistory(request)
      .pipe(
        finalize(() => {
          this.setHistoryGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setSalesGridHistoryList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  }
}
