import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { getFormValidation } from "src/Shared/Components";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { useStyles } from "./CompanyContacts.styles";
import MainContentBox from "src/Shared/Components/MainContentBox/MainContentBox";
import Title from "src/Shared/Components/Title/Title";
import { fields, hooks, labels, rules, values } from "./fields";
import { observer } from "mobx-react";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CompanyContactService from "../../Services/CompanyContact.service";
import { IAddCompanyContact, IRouteParams } from "../../Interfaces";
import { useNavigate, useParams } from "react-router";
import { finalize } from "rxjs";
import { ToastService } from "src/Shared/Services";
import { useStore } from "src/Shared/Hooks";
import { IResult } from "src/Shared/Interfaces";
import { AxiosError } from "axios";

const CompanyContact = () => {
  const contactForm = useMemo(
    () =>
      getFormValidation(
        { fields, labels, values, rules, hooks },
        {
          isNested: true,
        }
      ),
    []
  );

  const { companyRowID } = useParams<Partial<IRouteParams>>();
  const {
    rootStore: {
      uiStore,
      companyStore: { resetCompanyList },
    },
  } = useStore();
  const navigate = useNavigate();
  const classes = useStyles();

  const gridProps: IGridFormControlProps = {
    row: 2,
    col: 3,
    spacing: 4,
    itemResponsive: {
      lg: 6,
      md: 6,
      sm: 12,
    },
    inputControls: [
      {
        fieldKey: "fullName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "emailAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "jobTitle",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "phoneNumber",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        fieldKey: "isPrimaryContact",
        type: EDITOR_TYPES.SWITCH,
      },
      {
        fieldKey: "staffPrimaryOfficeAddress",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
    ],
  };
  const contacts = contactForm.$("contacts");

  const handleAddInput = () => {
    contacts.add({
      companyRowID: "",
      fullName: "",
      emailAddress: "",
      jobTitle: "",
      phoneNumber: "",
      isPrimaryContact: false,
      staffPrimaryOfficeAddress: "",
    });
  };

  const handleDeleteInput = (key) => {
    contacts.del(key);
  };

  const onSubmit = () => {
    if (!contactForm.isValid) {
      contactForm.showErrors(true);
      return;
    }
    const request: IAddCompanyContact[] = [...contactForm.values().contacts];
    request.map((x) => {
      x.isPrimaryContact =
        x.isPrimaryContact == "" || x.isPrimaryContact == null
          ? false
          : x.isPrimaryContact;
      return x;
    });
    request.forEach((x) => (x.companyRowID = companyRowID));
    uiStore.setGlobalLoader(true);
    CompanyContactService.addCompanyContactList(request)
      .pipe(
        finalize(() => {
          uiStore.setGlobalLoader(false);
        })
      )
      .subscribe({
        next: (data: IResult<string>) => {
          resetCompanyList();
          ToastService.success(data.message);
          navigate(`/company/${companyRowID}/addOffices`);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  return (
    <>
      <MainContentBox>
        <Title warningRequired title="Add New Client - Contacts"></Title>
        <Box className={classes.root}>
          {contacts &&
            contacts.map((field, index) => {
              return (
                <Box key={index} sx={{ mt: 4 }}>
                  <GridFormControl key={index} {...gridProps} form={field} />
                  {index > 0 && (
                    <Box className={classes.submitButtonBox}>
                      <Button
                        color="primary"
                        onClick={() => handleDeleteInput(field.key)}
                        startIcon={<DeleteTwoToneIcon />}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            })}
          <Button
            sx={{ height: "50px" }}
            color="primary"
            onClick={() => handleAddInput()}
            startIcon={<PersonAddAltTwoToneIcon />}
          >
            Add New Contact
          </Button>
        </Box>

        <Box className={classes.submitButtonBox}>
          <Button variant="contained" onClick={() => onSubmit()}>
            Add contacts
          </Button>
        </Box>
      </MainContentBox>
    </>
  );
};

export default observer(CompanyContact);
