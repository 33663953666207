import { Box } from "@mui/material";
import ModalContent from "../ModalContent/ModalContent";
import { useModal, useStore } from "src/Shared/Hooks";
import GridFormControl from "../GridFormControl/GridFormControl";
import { useEffect, useMemo, useState } from "react";
import { fields } from "./fields";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { getFormValidation } from "../FormValidations";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { IActionNote } from "src/Modules/Company/Interfaces/IActionNote";
import { observer } from "mobx-react";
type Props = {
  updateAction?: (val: IActionNote) => void;
  content?: string;
  isMarkAsCompleted?: boolean;
  title: string;
};
const AddNoteConfirmation: React.FC<Props> = ({
  content,
  updateAction,
  isMarkAsCompleted,
  title,
}) => {
  const {
    rootStore: {
      salesActionStore: { isNoteBtnLoading },
    },
  } = useStore();

  const { close } = useModal();
  const noteForm = useMemo(() => getFormValidation(fields()), []);
  const getField = (key: string) => noteForm.$(key);
  const gridPropsForNote: IGridFormControlProps = {
    row: 1,
    col: 1,
    itemResponsive: {
      lg: 12,
      sm: 12,
      md: 4,
    },
    inputControls: [
      {
        field: getField("note"),
        fieldKey: "note",
        type: EDITOR_TYPES.TEXT_FIELD,
        multiline: true,
        rows: 3,
      },
    ],
    form: noteForm,
  };

  const onConfirm = () => {
    if (!noteForm.isValid) {
      noteForm.showErrors();
      return;
    }
    const request: IActionNote = { ...noteForm.values() };
    request.isMarkAsCompleted = isMarkAsCompleted;
    updateAction(request);
  };

  return (
    <ModalContent
      maxWidth="xs"
      title={title}
      btnLoading={isNoteBtnLoading}
      buttonText="Confirm"
      onSaveButtonClick={() => onConfirm()}
      cancelButton={true}
      onCancel={() => close()}
    >
      <GridFormControl {...gridPropsForNote} />
      {isMarkAsCompleted && content}
    </ModalContent>
  );
};

export default observer(AddNoteConfirmation);
