import { Field } from "mobx-react-form";

export const fields = [
  "contacts",
  "contacts[].companyRowID",
  "contacts[].fullName",
  "contacts[].emailAddress",
  "contacts[].jobTitle",
  "contacts[].phoneNumber",
  "contacts[].isPrimaryContact",
  "contacts[].staffPrimaryOfficeAddress",
];

export const labels = {
  contacts: "Contacts ",
  "contacts[].fullName": "Full Name",
  "contacts[].emailAddress": "Email",
  "contacts[].jobTitle": "Job Title",
  "contacts[].phoneNumber": "Phone Number",
  "contacts[].isPrimaryContact": " Primary Contact",
  "contacts[].staffPrimaryOfficeAddress": "Address",
};

export const values = {
  contacts: [
    {
      companyRowID: "",
      fullName: "",
      emailAddress: "",
      jobTitle: "",
      phoneNumber: "",
      isPrimaryContact: true,
      staffPrimaryOfficeAddress: "",
    },
  ],
};

export const rules = {
  "contacts[].fullName": "string|required",
  "contacts[].emailAddress": "string|required|email",
  "contacts[].jobTitle": "string|required",
  "contacts[].phoneNumber": "string|required",
  "contacts[].isPrimaryContact": "boolean|required",
  "contacts[].staffPrimaryOfficeAddress": "string|required",
};

export const hooks = {
  contacts: {
    onAdd(instance: Field) {
      instance.state.form.$("contacts").map((v, i) => {
        v.$("isPrimaryContact").observe({
          key: "value",
          call: ({ form, field }) => {
            if (field.value) {
              form.$("contacts").map((contact, index) => {
                if (index !== i)
                  contact.$("isPrimaryContact").set("disabled", true);
              });
            } else {
              form.$("contacts").map((contact) => {
                contact.$("isPrimaryContact").set("disabled", false);
              });
            }
          },
        });
      });
      if (
        instance.state.form.values().contacts.some((x) => x.isPrimaryContact)
      ) {
        instance
          .$(`${instance.size - 1}`)
          .$("isPrimaryContact")
          .set("disabled", true);
      }
    },
  },
};

export default { fields, labels, values };
