import { Route, Routes } from "react-router-dom";
import CompanyGrid from "./Components/CompanyGrid/CompanyGrid";
import { Header } from "src/Shared/Layout/MainLayout";
import { Box } from "@mui/material";
import { AddOrEditCompany } from "./Components";
import CompanyOverview from "./Components/CompanyOverview/CompanyOverview";
import CompanyContact from "./Components/CompanyContact/CompanyContact";
import CompanyOfficeGrid from "./Components/CompanyOfficeGrid/CompanyOfficeGrid";
import CompanyContactGrid from "./Components/CompanyContactGrid/CompanyContactGrid";
import CompanyOffices from "./Components/CompanyOffice/CompanyOffices";

const CompanyModule = () => {
  return (
    <>
      <Box
        sx={{
          m: 1,
        }}
      >
        <Routes>
          <Route path="*" element={<CompanyGrid />}></Route>
          <Route path=":companyRowID?/:mode" element={<AddOrEditCompany />} />
          <Route path=":companyRowID/overview" element={<CompanyOverview />} />
          <Route
            path=":companyRowID/addContacts"
            element={<CompanyContact />}
          />
          <Route path=":companyRowID/offices" element={<CompanyOfficeGrid />} />
          <Route
            path=":companyRowID/contacts"
            element={<CompanyContactGrid />}
          />
          <Route path=":companyRowID/addOffices" element={<CompanyOffices />} />
        </Routes>
      </Box>
    </>
  );
};

export default CompanyModule;
