import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';

type Tab = {
  name: string;
  icons: any;
  link: string;
};

export const Tabs: Tab[] = [
  {
    name: "Home",
    icons: <HomeTwoToneIcon />,
    link: "company",
  },
  {
    name: "My Actions",
    icons: <AssignmentIndTwoToneIcon />,
    link: "assigned-actions",
  },
];
