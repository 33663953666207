import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { override } from "mobx";
import React from "react";

export interface Props extends DataGridProps {}

const CustomDataGrid: React.FC<Props> = (props) => {
  return (
    <div>
      <DataGrid {...props} />
    </div>
  );
};
CustomDataGrid.defaultProps = {
  disableColumnFilter: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  sortingMode: "server",
  filterMode: "server",
  scrollbarSize: 1,
  style: { height: "calc(100vh - 150px )" },
  pageSizeOptions: [10, 15, 20, 25],
  paginationMode: "server",
  rowSelection: false,
};
export default CustomDataGrid;
