import { IPageRequest, IPagedResult, IResult } from "src/Shared/Interfaces";
import { ICompanyList, ICompanyFilter, ICompany } from "../Interfaces";
import HttpClient from "src/Shared/Tools/HttpClient";
import { CompanyApiUrls } from "../Tools/CompanyApiUrls";
import { Observable } from "rxjs";
import { ICompanyOverview } from "../Interfaces/ICompanyOverview";
import { BaseService } from "src/Shared/Services/Base.service";
import { ICompanySearchFilter } from "../Interfaces/ICompanySearchFilter";
import { IArchivedCompany } from "../Interfaces/IArchivedCompany";

class CompanyService extends BaseService {
  public getCompanies(
    request: IPageRequest<ICompanyFilter>
  ): Observable<IPagedResult<ICompanyList>> {
    const http: HttpClient = new HttpClient();
    return http.post<IPagedResult<ICompanyList>>(
      CompanyApiUrls.GetCompanies,
      request
    );
  }

  public addCompany(request: ICompany) {
    const http: HttpClient = new HttpClient();
    return http.post<IResult<string>>(CompanyApiUrls.AddCompany, request);
  }

  public updateCompany(request: ICompany) {
    const http: HttpClient = new HttpClient();
    return http.post<IResult<string>>(CompanyApiUrls.UpdateCompany, request);
  }

  public getCompany(companyRowID: string) {
    const http: HttpClient = new HttpClient();
    return http.get<ICompany>(CompanyApiUrls.GetCompany(companyRowID));
  }

  public getArchivedCompany(archivedCRMDataID: number) {
    const http: HttpClient = new HttpClient();
    return http.get<IArchivedCompany>(
      CompanyApiUrls.GetArchivedCompany +
        `?archivedCRMDataID=${archivedCRMDataID}`
    );
  }

  public getCompanyOverview(companyRowID: string) {
    const http: HttpClient = new HttpClient();
    return http.get<ICompanyOverview>(
      CompanyApiUrls.GetCompanyOverview + `?companyRowID=${companyRowID}`
    );
  }

  public getCompanySearch(companyName: string, totalCount: number) {
    return this.http.get<ICompanySearchFilter[]>(
      CompanyApiUrls.Search(companyName, totalCount)
    );
  }
}
export default new CompanyService();
