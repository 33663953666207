import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

export const taskViewColumns = (editSalesAction, addNotes): GridColDef[] => {
  return [
    {
      field: "actionDescription",
      headerName: "Action Detail",
      editable: false,
      width: 500,
      sortable: false,
    },
    {
      field: "actionTypeName",
      headerName: "Action Type",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "actionTaskTypeName",
      headerName: "Action Category",
      editable: false,
      width: 200,
      sortable: false,
    },

    {
      field: "taskDueDate",
      headerName: "Due Date",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      editable: false,
      width: 200,
      sortable: false,
    },

    {
      field: "actionStatus",
      headerName: "Status",
      editable: false,
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              (params.row.actionStatus == "In Progress" && "orange") ||
              (params.row.actionStatus == "Completed" && "green") ||
              (params.row.actionStatus == "Overdue" && "red"),
            padding: params.value && "0px 10px 0px 10px",
            color: params.value && "white",
          }}
        >
          {params.row.actionStatus}
        </Box>
      ),
    },
    {
      field: "assignedToName",
      headerName: "Assigned To",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => editSalesAction(params.row)}>
          <EditTwoToneIcon />
        </IconButton>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => addNotes(params.row)}>
          <NoteAddIcon />
        </IconButton>
      ),
    },
  ];
};
