import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddOrEditSaleDeal from "../AddOrEditSaleDeal/AddOrEditSaleDeal";
import HistoryIcon from "@mui/icons-material/History";
import { numericFormatter } from "react-number-format";
import { ISalesDeal } from "../../Interfaces";

export const salesColumns = (editSalesDeals, openSalesDealHistory): GridColDef[] => {
  return [
    {
      field: "dealDescription",
      headerName: "Deal Description",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "dealStage",
      headerName: "Deal Stage",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "valueOfTesting",
      headerName: "Value of testing",
      editable: false,
      width: 120,
      sortable: false,
      valueFormatter(params) {
        return numericFormatter(`${params.value}`, {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: "$",
        });
      },
    },
    {
      field: "valueOfTraining",
      headerName: "Value of training",
      editable: false,
      width: 120,
      sortable: false,
      valueFormatter(params) {
        return numericFormatter(`${params.value}`, {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: "$",
        });
      },
    },
    {
      field: "valueOfPolicyReview",
      headerName: "Value of policy",
      editable: false,
      width: 120,
      sortable: false,
      valueFormatter(params) {
        return numericFormatter(`${params.value}`, {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: "$",
        });
      },
    },
    {
      field: "valueOfHealthTickServices",
      headerName: "Health tick",
      editable: false,
      width: 120,
      sortable: false,
      valueFormatter(params) {
        return numericFormatter(`${params.value}`, {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: "$",
        });
      },
    },
    {
      field: "totalValue",
      headerName: "Total",
      editable: false,
      width: 120,
      sortable: false,
      valueFormatter(params) {
        return numericFormatter(`${params.value}`, {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: "$",
        });
      },
    },
    {
      field: "createdBy",
      headerName: "Created By/On",
      editable: false,
      width: 200,
      sortable: false,
      renderCell: (params): any => {
        let row = params.row as ISalesDeal;
        return (
          <Box>
            <Box>{row.createdBy}</Box>
            <Box>{row.createdOn}</Box>
          </Box>
        );
      },
    },
    {
      field: "lastModifiedBy",
      headerName: "Modified By/On",
      editable: false,
      width: 200,
      sortable: false,
      renderCell: (params): any => {
        let row = params.row as ISalesDeal;
        return (
          <Box>
            <Box>{row.updatedBy}</Box>
            <Box>{row.updatedOn}</Box>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => editSalesDeals(params.row)}>
          <EditTwoToneIcon />
        </IconButton>
      ),
    },
    {
      field: "history",
      headerName: "History",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => openSalesDealHistory(params.row)}>
          <HistoryIcon />
        </IconButton>
      ),
    },
  ];
};
