import { Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { useStyles } from "./GlobalLoader.styles";

const GlobalLoader = () => {
  const {
    rootStore: { uiStore },
  } = useStore();

  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainContainer}>
        <CircularProgress size={100} />
      </Box>
    </>
  );
};

export default GlobalLoader;
