import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getFormValidation } from "src/Shared/Components";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import ViewInputControl from "src/Shared/Components/ViewInputControl/ViewInputControl";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { fields } from "./fields";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { ISalesAction } from "../../Interfaces";
import { IActionNote } from "../../Interfaces/IActionNote";
import SalesActionService from "../../Services/SalesAction.service";
import { finalize } from "rxjs";
import { IResult } from "src/Shared/Interfaces";
import { ToastService } from "src/Shared/Services";
import { AxiosError } from "axios";
import { useStore } from "src/Shared/Hooks";
import { observer } from "mobx-react";
import { useStyles } from "./AddOrEditNotes.styles";

type Props = {
  // mode: VIEW_MODE;
  saleAction?: ISalesAction;
  companyRowID?: string;
};

const AddOrEditNotes: React.FC<Props> = ({ saleAction, companyRowID }) => {
  const {
    rootStore: {
      salesActionStore: { notesList, getActionNotesList },
    },
  } = useStore();
  const notesForm = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => notesForm.$(key);
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    getActionNotesList(saleAction.companyActionID);
  }, []);

  const onSubmitNote = () => {
    if (!notesForm.isValid) {
      notesForm.showErrors();
      return;
    }
    const request: IActionNote = { ...notesForm.values() };
    request.companyActionID = saleAction.companyActionID;
    setLoading(true);

    SalesActionService.addActionNotes(request)
      .pipe(
        finalize(() => {
          setLoading(false);
        })
      )
      .subscribe({
        next: (data: IResult<string>) => {
          notesForm.clear();
          getActionNotesList(saleAction.companyActionID);
          ToastService.success(data.message);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  return (
    <>
      <ModalContent
        title="Action Notes"
        buttonText="Add"
        onSaveButtonClick={() => onSubmitNote()}
        btnLoading={loading}
      >
        <Box sx={{ mt: 2 }} className={classes.main}>
          <Box className={classes.root}>
            {notesList &&
              notesList.map((value) => {
                return (
                  <Container className={classes.notesGrid} fixed>
                    <Grid container className={classes.notes}>
                      <Grid item>
                        <Typography variant="body2">
                          {value.createdBy} {value.createdOn}:
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        key={value.companyActionID}
                        xs={12}
                        sx={{ mt: 2 }}
                      >
                        <Typography
                          className={classes.noteLine}
                          variant="subtitle1"
                        >
                          {value.note}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Container>
                );
              })}
          </Box>
          <Box sx={{ mt: 2 }}>
            <ViewInputControl
              type={EDITOR_TYPES.TEXT_FIELD}
              rows={2}
              form={notesForm}
              field={getField("note")}
              multiline={true}
            />
          </Box>
        </Box>
      </ModalContent>
    </>
  );
};

export default observer(AddOrEditNotes);
