import { ICompanyOverview } from "../../Interfaces/ICompanyOverview";
import { ICompanyOverviewPanelItem } from "../../Interfaces/ICompanyOverviewPanelItem";
import BusinessIcon from "@mui/icons-material/Business";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BadgeIcon from "@mui/icons-material/Badge";
import LanguageIcon from "@mui/icons-material/Language";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AttributionIcon from "@mui/icons-material/Attribution";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ModeIcon from "@mui/icons-material/Mode";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ContactsIcon from "@mui/icons-material/Contacts";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { FileUpload } from "@mui/icons-material";
import FileUploader from "src/Shared/Components/CoreUI/FileUploader/FileUploader";
import { numericFormatter } from "react-number-format";

const listToString = (list: string[]) => {
  return list?.join(", ") || "";
};
export const panelList = (
  companyOverview: ICompanyOverview
): ICompanyOverviewPanelItem[] => {
  return [
    {
      label: "Company Name:",
      value: companyOverview.companyName,
      icon: <BusinessIcon />,
    },
    {
      label: "National/Regional:",
      value: companyOverview.companyType,
      icon: <MyLocationIcon />,
    },
    {
      label: "Sales Pipeline Value:",
      value: numericFormatter(`${companyOverview.salesTotalValue}`, {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: "$",
      }),
      icon: <AttachMoneyIcon />,
    },
    {
      label: "Supporting Region:",
      value: companyOverview.franchiseName,
      icon: <MyLocationIcon />,
    },
    {
      label: "TDDA/Healthtick:",
      // value: companyOverview.vendorTypes,
      value: listToString(companyOverview.vendorTypes),
      icon: <BadgeIcon />,
    },
    {
      label: "Services:",
      value: listToString(companyOverview.services),
      icon: <LanguageIcon />,
    },
    {
      label: "Primary TDDA Relationship Manager:",
      value: companyOverview.primaryCompanyRelationshipManager,
      icon: <SocialDistanceIcon />,
    },
    {
      label: "CompanySize:",
      value: companyOverview.totalStaffNumber,
      icon: <SupervisorAccountIcon />,
    },
    {
      label: "Lead:",
      value: companyOverview.leadType,
      icon: <AttributionIcon />,
    },
    {
      label: "Deal Stage:",
      value: companyOverview.dealStage,
      icon: <LocalOfferIcon />,
    },
    {
      label: "Signed SLA:",
      value: companyOverview.isSLASigned ? "Yes" : "No",
      icon: <ModeIcon />,
    },
    {
      label: "SLA Expiry Date:",
      value: companyOverview.slaExpiryDate || "-",
      icon: <DateRangeIcon />,
    },
    {
      label: "Attachments:",
      value: (
        <FileUploader
          value={companyOverview.attachments}
          multiple={true}
          readonly={true}
          showLabel={false}
        ></FileUploader>
      ),
      icon: <AttachmentIcon />,
    },
    {
      label: "Client Primary Contact:",
      value: companyOverview.primaryContactName,
      icon: <ContactsIcon />,
    },
  ];
};
