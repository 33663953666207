import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { ICompanyOffice } from "../Interfaces/ICompanyOffice";
import { action, makeObservable, observable } from "mobx";
import { Subscription, finalize, forkJoin } from "rxjs";
import CompanyOfficeService from "../Services/CompanyOffice.service";
import { AxiosError } from "axios";
import { IOfficeFilter } from "../Interfaces/IOfficeFilter";
import { ToastService } from "src/Shared/Services";

export class CompanyOfficeStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;
  public primaryOfficeID: number | null = null;

  public companyOfficeList: IPagedResult<ICompanyOffice> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      companyOfficeList: observable,
      setGridLoading: action,
      getCompanyOffices: action.bound,
      setCompanyOfficesList: action,
      refreshCompanyOfficeList: action,
    });
    this.rootStore = rootStore;
  }

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  getCompanyOffices(request: IPageRequest<IOfficeFilter>): Subscription {
    this.setGridLoading(true);
    return forkJoin([
      CompanyOfficeService.getCompanyOfficesList(request),
      CompanyOfficeService.getPrimaryOfficeID(
        request.filterCollection.companyRowID
      ),
    ])
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: ([listdata, primaryOfficeID]) => {
          this.setCompanyOfficesList(listdata);
          this.setprimaryOfficeID(primaryOfficeID);
        },
        error: (error: AxiosError) =>  ToastService.axiosError(error),
      });
  }

  setCompanyOfficesList(data: IPagedResult<ICompanyOffice>) {
    if (data) {
      this.companyOfficeList = data;
    }
  }

  setprimaryOfficeID(val: number) {
    this.primaryOfficeID = val ? val : null;
  }

  refreshCompanyOfficeList = (companyRowID: string) => {
    this.getCompanyOffices({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
    });
  };

  resetCompanyList = () => {
    const companyOfficeList = { ...this.companyOfficeList };
    companyOfficeList.results = [];
    this.setCompanyOfficesList(companyOfficeList);
  };
}
