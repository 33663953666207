import React from "react";
import ModalContent from "../ModalContent/ModalContent";
import { useModal } from "src/Shared/Hooks";

type Props = {
  url: string;
};

const CustomPDFViewer: React.FC<Props> = ({ url }) => {
  const { close } = useModal();
  return (
    <>
      <ModalContent
        title="PDF Viewer"
        buttonText="Close"
        onSaveButtonClick={() => close()}
      >
        <div className="pdf-content-page">
          <iframe
            src={url}
            height="100%"
            width="98%"
            allowFullScreen={true}
          ></iframe>
        </div>
      </ModalContent>
    </>
  );
};

export default CustomPDFViewer;
