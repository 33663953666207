export const fields = {
    email: {
      label: 'Email',
      type: 'email',
      rules: 'required|string',
      placeholder:'Enter your email'
    },  
    password: {
      label: 'Password',
      rules: 'required|between:6,100',
      type: 'password',
      placeholder:'Enter your password'
    },
  };
  