import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { getFormValidation } from "src/Shared/Components";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import { VIEW_MODE } from "src/Shared/Enums/ViewMode.enum";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { fields } from "./fields";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { ISalesDeal } from "../../Interfaces";
import { DealStatus, EDITOR_TYPES } from "src/Shared/Enums";
import { useModal } from "src/Shared/Hooks";
import SalesDealService from "../../Services/SalesDeal.service";
import { finalize } from "rxjs";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import { IResult } from "src/Shared/Interfaces";
import { Box } from "@mui/material";

type Props = {
  mode: VIEW_MODE;
  saleDeal?: ISalesDeal;
  companyRowID?: string;
};

const AddOrEditSaleDeal: FC<Props> = ({ mode, saleDeal, companyRowID }) => {
  const {
    rootStore: {
      commonStore: { dealStatusList, loadCommonData },
      salesDealStore: { refreshSalesGridList },
      companyStore: { companyOverview, getCompanyOverview },
      uiStore,
    },
  } = useStore();

  const characterMaxLength = 400;
  const modal = useModal();
  const updateSalesTotalValue = () => {
    return getField("totalValue").set(
      Number(getField("valueOfHealthTickServices").value) +
        Number(getField("valueOfPolicyReview").value) +
        Number(getField("valueOfTraining").value) +
        Number(getField("valueOfTesting").value)
    );
  };

  const saleDealForm = useMemo(
    () => getFormValidation(fields(updateSalesTotalValue)),
    []
  );
  const [buttonText, setbuttonText] = useState<string>("Add");
  const [tittle, settittle] = useState<string>("");
  const getField = (key: string) => saleDealForm.$(key);

  useEffect(() => {
    loadCommonData();
    switch (mode) {
      case VIEW_MODE.ADD:
        {
          settittle(`Add Sales Deal - ${companyOverview.companyName}`);
        }
        break;
      case VIEW_MODE.EDIT:
        {
          setbuttonText("Update");
          settittle(`Edit Sales Deal - ${companyOverview.companyName}`);
        }
        break;
    }
  }, []);

  useEffect(() => {
    if (saleDeal && mode == VIEW_MODE.EDIT) {
      saleDealForm.set(saleDeal);

      if (saleDeal.valueOfTesting == 0)
        saleDealForm.set({ valueOfTesting: "0" });

      if (saleDeal.valueOfTraining == 0)
        saleDealForm.set({ valueOfTraining: "0" });

      if (saleDeal.valueOfPolicyReview == 0)
        saleDealForm.set({ valueOfPolicyReview: "0" });

      if (saleDeal.valueOfHealthTickServices == 0)
        saleDealForm.set({ valueOfHealthTickServices: "0" });

      if (
        saleDeal.valueOfPolicyReview == 0 &&
        saleDeal.valueOfTraining == 0 &&
        saleDeal.valueOfTesting == 0
      )
        saleDealForm.set({ totalValue: "0" });
    }
  }, [saleDeal]);

  const reload = () => {
    modal.close();
    refreshSalesGridList(companyRowID);
    getCompanyOverview(companyRowID);
  };

  const isReadOnly = () => {
    return (
      VIEW_MODE.EDIT == mode && DealStatus.ClosedLost == saleDeal.dealStatusID
    );
  };

  const [loading, setLoading] = useState<boolean>(false);

  const gridProps: IGridFormControlProps = {
    row: 1,
    col: 8,
    itemResponsive: {
      lg: 12,
      sm: 12,
      md: 4,
    },
    inputControls: [
      {
        field: getField("dealDescription"),
        fieldKey: "dealDescription",
        type: EDITOR_TYPES.TEXT_FIELD,
        multiline: true,
        rows: 3,
        inputProps: {
          readOnly: isReadOnly(),
          maxLength: characterMaxLength,
        },
        helperText: (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <span>
              {`${
                getField("dealDescription").value.length
              } / ${characterMaxLength}`}
            </span>
          </Box>
        ),
      },
      {
        field: getField("valueOfTesting"),
        fieldKey: "valueOfTesting",
        type: EDITOR_TYPES.TEXT_FIELD,
        isMasked: true,
        inputProps: {
          readOnly: isReadOnly(),
        },
      },
      {
        field: getField("valueOfTraining"),
        fieldKey: "valueOfTraining",
        type: EDITOR_TYPES.TEXT_FIELD,
        isMasked: true,
        inputProps: {
          readOnly: isReadOnly(),
        },
      },
      {
        field: getField("valueOfPolicyReview"),
        fieldKey: "valueOfPolicyReview",
        type: EDITOR_TYPES.TEXT_FIELD,
        isMasked: true,
        inputProps: {
          readOnly: isReadOnly(),
        },
      },
      {
        field: getField("valueOfHealthTickServices"),
        fieldKey: "valueOfHealthTickServices",
        type: EDITOR_TYPES.TEXT_FIELD,
        isMasked: true,
        inputProps: {
          readOnly: isReadOnly(),
        },
      },
      {
        field: getField("totalValue"),
        fieldKey: "totalValue",
        type: EDITOR_TYPES.TEXT_FIELD,
        inputProps: {
          readOnly: true,
        },
        isMasked: true,
      },
      {
        field: getField("dealStatusID"),
        fieldKey: "dealStatusID",
        type: EDITOR_TYPES.DROPDOWN,
        options: dealStatusList,
        inputProps: {
          readOnly: isReadOnly(),
        },
      },
    ],
    form: saleDealForm,
  };

  const onFromSubmit = () => {
    if (!saleDealForm.isValid) {
      saleDealForm.showErrors();
      return;
    }
    const request: ISalesDeal = { ...saleDealForm.values() };
    setLoading(true);
    switch (mode) {
      case VIEW_MODE.ADD:
        request.companyRowID = companyRowID;
        uiStore.setGlobalLoader(true);
        SalesDealService.addSaleDeal(request)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
      case VIEW_MODE.EDIT:
        uiStore.setGlobalLoader(true);
        SalesDealService.updateSaleDeal(request)
          .pipe(
            finalize(() => {
              setLoading(false);
              uiStore.setGlobalLoader(false);
            })
          )
          .subscribe({
            next: (data: IResult<string>) => {
              ToastService.success(data.message);
              reload();
            },
            error: (error: AxiosError) => ToastService.axiosError(error),
          });
        break;
    }
  };

  return (
    <ModalContent
      buttonText={buttonText}
      buttonDisabled={isReadOnly()}
      title={tittle}
      onSaveButtonClick={() => onFromSubmit()}
      btnLoading={loading}
    >
      <GridFormControl {...gridProps} />
    </ModalContent>
  );
};

export default observer(AddOrEditSaleDeal);
