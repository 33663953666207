import { observable, computed, action, makeObservable } from "mobx";
import { IUser } from "../Interfaces/IUser";
import AuthService from "../Services/Auth.service";
import { IRootStore } from "./Root.store";
export class AuthStore {
  public user: IUser | null = null;

  constructor() {
    makeObservable(this, {
      user: observable,
      isAuthenticated: computed,
      setUserDetail: action,
      hasToken: computed,
      validateToken: action,
      logout: action,
    });
  }

  public setUserDetail(user: IUser): void {
    this.user = user;
  }

  get isAuthenticated(): boolean {
    return this.user !== null;
  }

  public validateToken() {
    return AuthService.isTokenValid();
  }
  get hasToken() {
    return localStorage.getItem("AuthToken") !== null;
  }

  public logout(): void {
    AuthService.logout();
  }
}
